var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "callAll", style: { height: _vm.height - 80 + "px" } },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        _vm._l(_vm.dataList, function (item, index) {
          return _c("defaultItem", {
            key: index,
            attrs: { item: item, dropdownlist: _vm.dropdownlist },
            on: {
              handleCommand: _vm.handleCommand,
              editorName: _vm.editorName,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }