import request from '@/utils/request';
var api = {
  getLoginLog: function getLoginLog(params) {
    return request({
      url: '/api/admin/getLoginList',
      method: 'get',
      params: params
    });
  },
  // 获取系统配置列表
  getSystemConfigList: function getSystemConfigList(params) {
    return request({
      url: '/api/admin/property/list',
      method: 'get',
      params: params
    });
  },
  // 更新系统配置
  updateSystemConfig: function updateSystemConfig(data) {
    return request({
      url: '/api/admin/property/update',
      method: 'post',
      data: data
    });
  },
  // 添加系统配置
  addSystemConfig: function addSystemConfig(data) {
    return request({
      url: '/api/admin/property/insert',
      method: 'post',
      data: data
    });
  },
  // 删除系统配置
  deleteSysTemConfigList: function deleteSysTemConfigList(params) {
    return request({
      url: '/api/admin/property/delete',
      method: 'get',
      params: params
    });
  }
};
export default api;