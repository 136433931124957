import request from '@/utils/request';
var lostApi = {
  /**
   *
   * @param data phone:string
   */
  sendLostFormSms: function sendLostFormSms(data) {
    return request({
      url: "api/admin/lost/sendFormSms",
      method: 'post',
      params: data
    });
  },
  sendDriverRewardSms: function sendDriverRewardSms(params) {
    return request({
      url: "api/admin/lost/sendDriverRewardSms",
      method: 'post',
      params: params
    });
  },
  getAdminUserOrderPerformances: function getAdminUserOrderPerformances(params) {
    return request({
      url: "/api/admin/lost/orderperformances",
      params: params,
      method: 'get'
    });
  },
  getAdminUserPerformances: function getAdminUserPerformances(params) {
    return request({
      url: "/api/admin/lost/performancestat",
      params: params,
      method: 'get'
    });
  },
  getStatistics: function getStatistics() {
    return request({
      url: "/api/admin/lost/statistics",
      method: 'get'
    });
  },
  updateOwnerConfirm: function updateOwnerConfirm(lostId, data) {
    return request({
      url: "/api/admin/lost/order/".concat(lostId, "/ownerConfirmInfo"),
      method: 'post',
      params: data
    });
  },
  updateMatchDriveRemark: function updateMatchDriveRemark(lostId, data) {
    return request({
      url: "/api/admin/lost/order/".concat(lostId, "/matchDriveRemark"),
      method: 'post',
      params: data
    });
  },
  updateHandleResult: function updateHandleResult(id, data) {
    return request({
      url: "/api/admin/lost/order/".concat(id, "/handleResult"),
      method: 'post',
      params: data
    });
  },
  createRefundOrder: function createRefundOrder(lostid, params) {
    return request({
      url: "/api/admin/lost/order/".concat(lostid, "/refund"),
      method: 'post',
      params: params
    });
  },
  updateLostInfo: function updateLostInfo(id, data) {
    return request({
      url: "/api/admin/lost/order/".concat(id, "/info"),
      method: 'post',
      data: data
    });
  },
  createOrder: function createOrder(lostInfo) {
    return request({
      url: "/api/admin/lost/orders",
      method: 'post',
      data: lostInfo
    });
  },
  /**
   * 更新给乘客看的关于失物在车上的确认信息
   * @param id
   * @param data
   */
  updatePropertyConfirm: function updatePropertyConfirm(id, data) {
    return request({
      url: "/api/admin/lost/order/".concat(id, "/propertyConfirm"),
      method: 'post',
      params: data
    });
  },
  /**
   * 更新给乘客看的关于失物送还的信息
   * @param id
   * @param data
   */
  updatePropertyReturn: function updatePropertyReturn(id, data) {
    return request({
      url: "/api/admin/lost/order/".concat(id, "/propertyReturn"),
      method: 'post',
      params: data
    });
  },
  updateMatchInfo: function updateMatchInfo(id, data) {
    return request({
      url: "/api/admin/lost/order/".concat(id, "/matchInfo"),
      method: 'post',
      params: data
    });
  },
  updateHandleStatus: function updateHandleStatus(id, handleStatus) {
    return request({
      url: "/api/admin/lost/order/".concat(id, "/handleStatus"),
      method: 'post',
      params: {
        value: handleStatus
      }
    });
  },
  /*
   @param data {canceled, cancelRemark}
   */
  updateCancel: function updateCancel(id, data) {
    return request({
      url: "/api/admin/lost/order/".concat(id, "/cancel"),
      method: 'post',
      params: data
    });
  },
  loadServiceInfo: function loadServiceInfo(lostId) {
    return request({
      url: "/api/admin/lost/order/".concat(lostId, "/serviceInfo"),
      method: 'get'
    });
  },
  loadRefundInfo: function loadRefundInfo(lostId) {
    return request({
      url: "/api/admin/lost/order/".concat(lostId, "/refund"),
      method: 'get'
    });
  },
  loadRewardInfo: function loadRewardInfo(lostId) {
    return request({
      url: "/api/admin/lost/order/".concat(lostId, "/reward"),
      method: 'get'
    });
  },
  /**
   *  加载所有司机的奖励
   * @param params 参数对象。对象的 payStatus 表示奖励的支付状态过滤条件
   */
  loadDriverRewards: function loadDriverRewards(params) {
    return request({
      url: "api/admin/lost/rewards",
      method: 'get',
      params: params
    });
  },
  loadLostFinanceOrders: function loadLostFinanceOrders(params) {
    return request({
      url: "api/admin/lost/finance/orders",
      method: 'get',
      params: params
    });
  },
  loadLostOrders: function loadLostOrders(params) {
    return request({
      url: "api/admin/lost/orders",
      method: 'get',
      params: params
    });
  },
  // 发送失物查找司机奖励微信红包给微信司机用户
  sendRedpackWx: function sendRedpackWx(lostId) {
    return request({
      url: "api/admin/lost/outlay/sendReward",
      method: 'post',
      params: {
        lostId: lostId
      }
    });
  },
  // 更新银联转账结果
  updateUnionPayInfo: function updateUnionPayInfo(lostId, serialNum, payTime) {
    return request({
      url: "/api/admin/lost/outlay/ unionpay",
      method: 'post',
      params: {
        lostId: lostId,
        serialNum: serialNum,
        payTime: payTime
      }
    });
  },
  // 修改司机奖励银行卡信息
  changeBank: function changeBank(id, params) {
    return request({
      url: "/api/admin/lost/outlay/".concat(id, "/bankcard"),
      method: 'post',
      params: params
    });
  },
  // 获取蛋卷出行司机端司机信息
  loadDriverInfo: function loadDriverInfo(params) {
    return request({
      url: "/api/admin/driver/info",
      method: 'get',
      params: params
    });
  },
  // 获取统计数据
  loadStatistics: function loadStatistics() {
    return request({
      url: "/api/admin/lost/statistics",
      method: 'get'
    });
  },
  /**
   * 更新司机奖励的湾行银行转账回执
   * @param data 数组，包含订单ID、支付结果
   */
  updateBankBbwTransactionReceipt: function updateBankBbwTransactionReceipt(outlayId, data) {
    return request({
      url: "api/admin/lost/outlay/".concat(outlayId, "/bbwTransaction/receipt"),
      method: 'post',
      data: data
    });
  },
  /**
   * 更新司机奖励的湾行银行转账回执
   * @param data 数组，包含订单ID、支付结果
   */
  updateBankBbwTransactionReceipts: function updateBankBbwTransactionReceipts(data) {
    return request({
      url: "api/admin/lost/outlay/bbwTransaction/receipts",
      method: 'post',
      data: data
    });
  },
  // POST  更新失物订单处理人
  updateHandleUser: function updateHandleUser(data, id) {
    return request({
      url: "/api/admin/lost/order/".concat(id, "/handleUser"),
      method: 'post',
      params: data
    });
  },
  // GET /api/admin/users 获取所有管理员用户
  loadHandleUser: function loadHandleUser() {
    return request({
      url: "/api/admin/users",
      method: 'get'
    });
  },
  //获取行业平台司机信息
  getDriverInfo: function getDriverInfo(brandNumber) {
    return request({
      url: "api/admin/lost/realDrivers?vehicleNo=".concat(brandNumber),
      method: 'get'
    });
  }
};
export default lostApi;