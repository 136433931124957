import request from '@/utils/adRequest';
var api = {
  // 获取车辆信息
  loadVehicleList: function loadVehicleList() {
    return request({
      url: "/api/admin/vehicle/list",
      method: 'get'
    });
  },
  // 获取车辆列表
  getVehicleList: function getVehicleList(params) {
    return request({
      url: '/api/ad/mach/vehicleInfo',
      method: 'get',
      params: params
    });
  },
  // 车辆操作日志
  getVehicleOperateLog: function getVehicleOperateLog(params) {
    return request({
      url: '/api/ad/mach/admin/vehicleOperateLog',
      method: 'get',
      params: params
    });
  },
  // 更新车辆信息
  updateVehicleInfo: function updateVehicleInfo(data) {
    return request({
      url: '/api/ad/mach/admin/vehicleInfo',
      method: 'post',
      data: data
    });
  },
  // 获取车辆品牌和型号
  getBrand: function getBrand(params) {
    return request({
      url: '/api/ad/mach/admin/allVehicleMakeListVo',
      method: 'get',
      params: params
    });
  },
  // 附件未处理的车辆统计
  getEnclosureNum: function getEnclosureNum() {
    return request({
      url: '/api/ad/mach/notHandleAttachmentVehicleCount',
      method: 'get'
    });
  }
};
export default api;