import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var CHE_TOKEN_KEY = 'CHE_TOKEN';
export function getCheToken() {
  return Cookies.get(CHE_TOKEN_KEY);
}
export function setCheToken(token) {
  return Cookies.set(CHE_TOKEN_KEY, token);
}
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}