import request from '@/utils/request';
var financeApi = {
  getAllIncomes: function getAllIncomes(params) {
    return request({
      url: "/api/admin/finance/incomes",
      method: 'get',
      params: params
    });
  },
  getOutlays: function getOutlays(params) {
    return request({
      url: "/api/admin/finance/outlays",
      method: 'get',
      params: params
    });
  }
};
export default financeApi;