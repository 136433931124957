import _objectSpread from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/api/admin/auth/login',
    method: 'post',
    data: data
  });
}
export function renewMyPassword(data) {
  return request({
    url: '/api/admin/auth/renewMyPassword',
    method: 'post',
    params: data
  });
}
export function getInfo(token) {
  return request({
    url: 'api/admin/user',
    method: 'get'
  });
}
export function getAdminUsers(params) {
  return request({
    url: 'api/admin/user/list',
    method: 'get',
    params: params
  });
}
export function logout() {
  return request({
    url: 'api/admin/auth/logout',
    method: 'post'
  });
}
//密码过期重置密码
export function resetPassword(data) {
  return request({
    url: 'api/admin/auth/renewPassword',
    method: 'post',
    headers: _objectSpread({}, data)
  });
}