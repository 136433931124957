import api from '@/api/callStatistics';
var searchConfig = [{
  title: '接听坐席',
  mobile: 'agentId',
  placeholder: '请选择',
  options: [],
  type: 'requestSelect',
  request: api.customList,
  format: {
    value: 'agent',
    label: 'agentName'
  }
}, {
  title: '通话状态类型',
  mobile: 'state',
  placeholder: '请选择',
  type: 'select',
  options: [{
    label: '所有',
    value: ''
  }, {
    label: '已接听',
    value: 'dealing'
  }, {
    label: '振铃未接听',
    value: 'notDeal'
  }, {
    label: 'ivr放弃',
    value: 'leak'
  }, {
    label: '排队放弃',
    value: 'queueLeak'
  }, {
    label: '黑名单',
    value: 'blackList'
  }, {
    label: '留言',
    value: 'voicemail'
  }]
}, {
  title: '通话标签',
  mobile: 'tagging',
  type: 'slot'
}];
export { searchConfig };