var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "appWrapper", staticClass: "app-wrapper" },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-aside",
            {
              staticClass: "transition",
              staticStyle: {
                padding: "0",
                "margin-bottom": "0",
                "background-color": "#FFFFFF",
              },
              attrs: { width: _vm.asideWidth },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    width: "100%",
                    height: "100%",
                  },
                },
                [
                  _c("sidebar"),
                  _vm._v(" "),
                  _c("CallDrawer", { attrs: { showState: _vm.drawerState } }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-container",
            [
              _c(
                "el-header",
                {
                  staticStyle: {
                    "font-size": "12px",
                    padding: "0",
                    height: "83px",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("navbar"),
                      _vm._v(" "),
                      _vm.needTagsView ? _c("tags-view") : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-main",
                {
                  staticStyle: { padding: "0", width: "100%", height: "100%" },
                  attrs: { id: "appVisual" },
                },
                [_c("app-main")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }