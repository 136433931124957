//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: process.env.VUE_APP_DOCUMENT_TITLE,
      logo_big: require(process.env.VUE_APP_LOGO_LARGE),
      logo_small: require(process.env.VUE_APP_LOGO_SMALL)
    };
  }
};