import request from '@/utils/request';
var driverApi = {
  postVersions: function postVersions(data) {
    return request({
      url: "api/admin/version/versions",
      method: 'post',
      data: data
    });
  },
  putVersions: function putVersions(data) {
    return request({
      url: "api/admin/version/versions",
      method: 'put',
      data: data
    });
  },
  deleteVersion: function deleteVersion(versionId) {
    return request({
      url: "api/admin/version/versions/".concat(versionId),
      method: 'delete'
    });
  },
  getVersions: function getVersions(app, platform) {
    return request({
      url: "api/admin/version/versions",
      method: 'get',
      params: {
        app: app,
        platform: platform
      }
    });
  }
};
export default driverApi;