import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
var interceptor = {
  requestInterceptor: function requestInterceptor(config) {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken();
    }
    return config;
  },
  requestOnReject: function requestOnReject(error) {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
  responseInterceptor: function responseInterceptor(response) {
    var res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.status != 200) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 10 * 1000
      // })

      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  },
  responseRejected: function responseRejected(error) {
    console.log('err' + error); // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  },
  configAxios: function configAxios(service) {
    // request interceptor
    service.interceptors.request.use(this.requestInterceptor, this.requestOnReject);

    // response interceptor
    service.interceptors.response.use(this.responseInterceptor, this.responseRejected);
  }
};
export default interceptor;