import _toConsumableArray from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//

import defaultItem from "./defaultItem";
import api from '@/api/dispatch';
import dictApi from "@/api/dict";
export default {
  components: {
    defaultItem: defaultItem
  },
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    dataList: {
      get: function get() {
        return this.list;
      },
      set: function set(val) {
        return val;
      }
    }
  },
  data: function data() {
    return {
      height: 0,
      dropdownlist: []
    };
  },
  mounted: function mounted() {
    this.init();
    var _this = this;
    this.$EventBus.$on('updateCall', function (msg) {
      // A发送来的消息
      if (Array.isArray(_this.dataList)) {
        for (var item in _this.dataList) {
          if (_this.dataList[item]['id'] === msg['id']) {
            _this.dataList.splice(item, 1, msg);
          }
        }
      }
    });
  },
  methods: {
    /**
     * 初始化数据和计算高度
     */
    init: function init() {
      var _this2 = this;
      var appMain = document.getElementById('app');
      if (!this.height) {
        this.height = appMain.clientHeight;
      }
      var _this = this;
      window.onresize = function () {
        // 定义窗口大小变更通知事件
        var height = appMain.clientHeight; // 窗口高度
        _this.$nextTick(function () {
          if (_this.height !== height) {
            _this.height = height;
          }
        });
      };
      dictApi.queryQimoDictList().then(function (val) {
        var status = val.status,
          data = val.data;
        if (status === '0') {
          if (Array.isArray(data)) {
            _this2.dropdownlist = _toConsumableArray(data);
          }
        }
      }).catch(function (err) {
        return console.log(err);
      });
    },
    /**
     * 处理标记
     * @param params 处理标记结果 {id, command}
     */
    handleCommand: function handleCommand(params) {
      var _this3 = this;
      api.updateQimoTagging(params).then(function (val) {
        var status = val.status;
        if (status === '0') {
          _this3.$message({
            message: '标记成功',
            type: 'success'
          });
          var paras = {
            id: params.id
          };
          _this3.getData(paras);
        }
      }).catch(function () {
        _this3.$message.error('操作失败');
      });
    },
    /**
     * 编辑名称
     * @param value 处理标记结果 {id, name}
     */
    editorName: function editorName(value) {
      var _this4 = this;
      var params = value.params,
        id = value.id;
      api.updateQimoUser(params).then(function (val) {
        var status = val.status;
        if (status === '0') {
          _this4.$message({
            message: '编辑成功',
            type: 'success'
          });
          var paras = {
            id: id
          };
          _this4.getData(paras);
        }
      }).catch(function () {
        _this4.$message.error('操作失败');
      });
    },
    /**
     * 请求数据
     * @param params {Object} 请求参数
     */
    getData: function getData(params) {
      var _this = this;
      api.queryCallRecordList(params).then(function (val) {
        var status = val.status,
          data = val.data;
        if (status === '0') {
          if (data.recordExpands !== null && data.recordExpands !== undefined) {
            var msg = data.recordExpands[0];
            if (Array.isArray(_this.dataList)) {
              for (var item in _this.dataList) {
                if (_this.dataList[item]['id'] === msg['id']) {
                  _this.dataList.splice(item, 1, msg);
                }
              }
            }
            _this.$EventBus.$emit('callUpdateCall', msg);
          }
        }
      }).catch(function (err) {
        return console.log(err);
      });
    }
  }
};