var state = {
  drawerData: {}
};
var mutations = {
  DRAWER_STATE: function DRAWER_STATE(state, value) {
    state.drawerData = value;
  }
};
var actions = {
  // addErrorLog({ commit }, log) {
  //   commit('ADD_ERROR_LOG', log)
  // }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};