import moment from 'moment';
export function formatStandardUnixTime(str) {
  if (str == null) {
    return '-';
  }
  if (_.isNumber(str)) {
    return moment.unix(parseInt(str) / 1000).format('YYYY-MM-DD HH:mm:ss');
  }
  return '-';
}
var dateutil = {
  formatStandardTime: formatStandardUnixTime,
  getTimeStrFromMonthPickerArray: function getTimeStrFromMonthPickerArray(timePickerValue) {
    var res = {};
    if (_.isArray(timePickerValue) && !_.isEmpty(timePickerValue)) {
      res.from = moment(timePickerValue[0]).format('YYYY-MM-DD HH:mm:ss');
    } else {
      res.from = null;
    }
    if (_.isArray(timePickerValue) && timePickerValue.length > 1) {
      res.to = moment(timePickerValue[1]).endOf("month").endOf("day").format('YYYY-MM-DD HH:mm:ss');
      console.log(moment(timePickerValue[1]));
      console.log(moment(timePickerValue[1]).endOf("month"));
    } else {
      res.to = null;
    }
    return res;
  },
  getTimeStrFromPickerArray: function getTimeStrFromPickerArray(timePickerValue) {
    var res = {};
    if (_.isArray(timePickerValue) && !_.isEmpty(timePickerValue)) {
      res.from = moment(timePickerValue[0]).format('YYYY-MM-DD HH:mm:ss');
    } else {
      res.from = null;
    }
    if (_.isArray(timePickerValue) && timePickerValue.length > 1) {
      res.to = moment(timePickerValue[1]).format('YYYY-MM-DD HH:mm:ss');
    } else {
      res.to = null;
    }
    return res;
  },
  getTimeStrFromPickerArray2: function getTimeStrFromPickerArray2(timePickerValue) {
    var res = {};
    if (_.isArray(timePickerValue) && !_.isEmpty(timePickerValue)) {
      res.from = moment(timePickerValue[0]).format('YYYY/MM/DD HH:mm:ss');
    } else {
      res.from = null;
    }
    if (_.isArray(timePickerValue) && timePickerValue.length > 1) {
      res.to = moment(timePickerValue[1]).format('YYYY/MM/DD HH:mm:ss');
    } else {
      res.to = null;
    }
    console.log("date-res", res);
    return res;
  }
};
export default dateutil;