var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "highlight-current-row": _vm.highlightCurrentRow,
            "show-summary": _vm.showSummary,
            "sum-text": _vm.sumText,
            fit: _vm.fit,
            "summary-method": _vm.summaryMethod,
            "row-key": _vm.rowKey,
            "expand-row-keys": _vm.expandRowKeys,
            "row-class-name": _vm.tableRowClassName,
            height: _vm.height,
            "max-height": _vm.maxHeight,
            "default-expand-all": _vm.defaultExpandAll,
            "tree-props": _vm.treeProps,
            border: _vm.border,
            stripe: _vm.stripe,
          },
          on: {
            select: _vm.select,
            "select-all": _vm.selectAll,
            "selection-change": _vm.selectChange,
            "row-click": _vm.rowClick,
            "current-change": _vm.currentChange,
            "expand-change": _vm.expandChange,
          },
        },
        [
          _vm.selection
            ? _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.config, function (item, index) {
            return [
              item.formatter
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      align: item.align,
                      "min-width": item.minWidth,
                      width: item.width,
                      formatter: item.formatter,
                    },
                  })
                : _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      align: item.align,
                      "min-width": item.minWidth,
                      width: item.width,
                      fixed: item.fixed,
                      type: item.type,
                      sortable: item.sortable,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.slot
                                ? [
                                    _vm._t(item.prop, null, {
                                      row: scope.row,
                                      prop: item.prop,
                                      index: scope.$index,
                                    }),
                                  ]
                                : [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.getByKey(scope.row, item.prop)
                                        ) +
                                        "\n          "
                                    ),
                                  ],
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }