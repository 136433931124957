import request from '@/utils/request';
var api = {
  getLogList: function getLogList(data, params) {
    return request({
      url: '/api/admin/log/list',
      method: 'post',
      data: data,
      params: params
    });
  }
};
export default api;