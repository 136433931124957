import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/core-js/modules/es6.promise.js";
import "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import { mapState } from 'vuex';
import Cookies from 'js-cookie';
import "uno.css";
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import Element from 'element-ui';
// import './styles/element-variables.scss'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'; // global css
import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // internationalization
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error log
import * as filters from "./filters"; // global filters
import consts from "./common/consts";
import { parseTime } from "./utils/datetime";
import { abilitiesPlugin } from '@casl/vue';
import ability from "./config/ability";
// This package provides a Vue plugin which defines $ability object and $can method for all components
Vue.use(abilitiesPlugin, ability);
import advertisementApi from '@/api/advertisementApi';
import adminUserApi from '@/api/adminuser';
import versionApi from '@/api/version';
import driverApi from '@/api/driver';
import cheApi from '@/api/che';
import testApi from '@/api/test';
import dispatchApi from '@/api/dispatch';
import orderApi from '@/api/order';
import alarmApi from '@/api/alarm';
import checkApi from '@/api/check';
import systemConfigApi from '@/api/system';
import operateApi from '@/api/operatelog';
import taxiGisApi from '@/api/taxigis';
import vehGisApi from '@/api/vehgis';
import driverhome from '@/api/driverhome';
Vue.prototype.$EventBus = new Vue();
import * as _ from 'lodash';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import VueDND from 'awe-dnd';

// 引入echarts
import * as echarts from 'echarts';
// 挂载echarts
Vue.prototype.$echarts = echarts;
Vue.prototype.parseTime = parseTime;
import DictTag from '@/components/DictTag/index';
Vue.component('dict-tag', DictTag);
Vue.use(VueDND);
Vue.use(Viewer);
import elDragDialog from "./directive/el-drag-dialog";
Vue.use(elDragDialog);
Vue.mixin({
  data: function data() {
    return {
      get consts() {
        return consts;
      },
      get isProduction() {
        return process.env.NODE_ENV === 'production';
      },
      get lo() {
        return _;
      },
      get api() {
        return {
          ad: advertisementApi,
          driver: driverApi,
          version: versionApi,
          user: adminUserApi,
          che: cheApi,
          test: testApi,
          dispatch: dispatchApi,
          order: orderApi,
          check: checkApi,
          alarm: alarmApi,
          operate: operateApi,
          taxigis: taxiGisApi,
          vehgis: vehGisApi,
          driverhome: driverhome,
          systemConfigApi: systemConfigApi
        };
      }
    };
  },
  computed: _objectSpread({}, mapState({
    dicts: function dicts(state) {
      return state.dict.dicts;
    }
  })),
  methods: {
    /**
     * 根据对象是否有指定的表示时间的属性，给对象设置一个字符串格式化后的时间属性，便于在界面上显示
     * @param object
     * @param propName
     * @param momentFmt
     */
    setTimeText: function setTimeText(object, propName, momentFmt) {
      if (_.has(object, propName)) {
        var dateVal = _.get(object, propName);
        if (dateVal) {
          _.set(object, propName + 'Text', moment(dateVal, momentFmt));
        } else {
          _.set(object, propName + 'Text', '');
        }
      }
    },
    isVehicleNumber: function isVehicleNumber(vehicleNumber) {
      var xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
      var creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
      if (vehicleNumber.length === 7) {
        return creg.test(vehicleNumber);
      } else if (vehicleNumber.length === 8) {
        return xreg.test(vehicleNumber);
      } else {
        return false;
      }
    },
    // 去掉"桂A"有效的车牌号部分
    isVehicleNumberPostfix: function isVehicleNumberPostfix(vehicleNumber) {
      if (vehicleNumber.length === 5) {
        return this.isVehicleNumber('桂A' + vehicleNumber);
      } else if (vehicleNumber.length === 6) {
        return this.isVehicleNumber('桂A' + vehicleNumber);
      } else {
        return false;
      }
    }
  }
});

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
import { mockXHR } from "../mock";
import { computed } from 'vue';

// if (process.env.NODE_ENV === 'production') {
//   mockXHR()
// }

Vue.use(Element, {
  size: Cookies.get('size') || 'mini',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});