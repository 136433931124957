import request from '@/utils/adRequest';
var api = {
  // 获取登记中的所有的公司名称
  getAdProperties: function getAdProperties(params) {
    return request({
      url: "/api/ad/admin/app/adProperties",
      method: 'get',
      params: params
    });
  },
  // 获取登记中的所有的公司名称
  loadAllCarCompanyNames: function loadAllCarCompanyNames() {
    return request({
      url: "/api/ad/admin/allCarCompanyNames",
      method: 'get'
    });
  },
  // 查询上画记录
  loadAdPastePictureLogs: function loadAdPastePictureLogs(params) {
    return request({
      url: "/api/ad/admin/adPastePictureLogs",
      method: 'get',
      params: params
    });
  },
  // 修改上画记录
  editAdPastePictureLog: function editAdPastePictureLog(params) {
    return request({
      url: "/api/ad/admin/adPastePictureLog",
      method: 'post',
      params: params
    });
  },
  // 获取客户列表
  loadAdCustomers: function loadAdCustomers(params) {
    return request({
      url: "/api/ad/admin/adCustomers",
      method: 'get',
      params: params
    });
  },
  // 添加客户
  createAdCustomers: function createAdCustomers(params) {
    return request({
      url: "/api/ad/admin/adCustomers",
      method: 'post',
      data: params
    });
  },
  // 修改客户信息
  editAdCustomers: function editAdCustomers(params) {
    return request({
      url: "/api/ad/admin/adCustomer",
      method: 'post',
      data: params
    });
  },
  // 删除客户信息
  deleteAdCustomers: function deleteAdCustomers(customerId) {
    return request({
      url: "/api/ad/admin/adCustomer/".concat(customerId, " "),
      method: 'delete'
    });
  },
  // 查询项目列表（上画期数）
  loadAdInstallments: function loadAdInstallments(params) {
    return request({
      url: "/api/ad/admin/adInstallments",
      method: 'get',
      params: params
    });
  },
  // 修改项目（上画期数）
  editAdInstallment: function editAdInstallment(params) {
    return request({
      url: "/api/ad/admin/adInstallments/v3",
      method: 'put',
      data: params
    });
  },
  // 添加项目（上画期数）未实现
  createAdInstallment: function createAdInstallment(params) {
    return request({
      url: "/api/ad/admin/adInstallments/v3",
      method: 'post',
      data: params
    });
  },
  // 标记画面下刊,如果画面已经下刊，则返回成功但是不进行任何更新,返回司机下画类型
  rivePicture: function rivePicture(params) {
    return request({
      url: "/api/ad/app/adPastePictureLog/rivePicture",
      method: 'post',
      params: params
    });
  },
  // 获取上画品类
  loadAdPictureTypes: function loadAdPictureTypes(params) {
    return request({
      url: "/api/ad/admin/adPictureTypes",
      method: 'get',
      params: params
    });
  },
  // 新增上画品类
  addAdPictureType: function addAdPictureType(params) {
    return request({
      url: "/api/ad/admin/adPictureTypes",
      method: 'post',
      params: params
    });
  },
  // 查询礼物信息
  loadAdDriverGifts: function loadAdDriverGifts(params) {
    return request({
      url: "/api/ad/admin/adDriverGifts",
      method: 'get',
      params: params
    });
  },
  // 修改礼物信息
  editAdDriverGift: function editAdDriverGift(params) {
    return request({
      url: "/api/ad/admin/adDriverGift",
      method: 'put',
      params: params
    });
  },
  // 添加礼物信息
  createAdDriverGifts: function createAdDriverGifts(params) {
    return request({
      url: "/api/ad/admin/adDriverGifts",
      method: 'post',
      params: params
    });
  },
  // 按筛选条件导出上画记录，返回文件的下载链接
  exportAdList: function exportAdList(params) {
    return request({
      url: "/api/ad/admin/adPastePictureLogs/export",
      method: 'post',
      params: params
    });
  },
  // 按筛选条件导出上画记录，返回文件的下载链接
  exportAdListV2: function exportAdListV2(params) {
    return request({
      url: "/api/ad/admin/adPastePictureLogs/export/v2",
      method: 'post',
      params: params
    });
  },
  // 按筛选条件导出上画记录，返回文件的下载链接
  exportAdListV3: function exportAdListV3(params) {
    return request({
      url: "/api/ad/admin/adPastePictureLogs/export/v3",
      method: 'post',
      params: params
    });
  },
  // 查询上画记录的备注图
  getAdRemarks: function getAdRemarks(params) {
    return request({
      url: "/api/ad/admin/adRemarks",
      method: 'get',
      params: params
    });
  },
  // 获取阿里云OSS上传文件凭证，
  getAliyunOSSAccessToken: function getAliyunOSSAccessToken() {
    return request({
      url: "/api/ad/admin/aliyunOSSAccessToken",
      method: 'get'
    });
  },
  // 文件上传报备,返回上传路径和回调地址
  beforeUploadFile: function beforeUploadFile(params) {
    return request({
      url: "/api/ad/admin/beforeUploadFile",
      method: 'get',
      params: params
    });
  },
  // 广告上画记录-上传图片(后台上传默认审核通过)
  uploadImage: function uploadImage(data) {
    return request({
      url: "/api/ad/admin/adPastePictureLog/uploadImage",
      method: 'put',
      data: data
    });
  },
  // 添加已领取10元的拍照奖励记录
  addAdDriverPhotoRewardLog: function addAdDriverPhotoRewardLog(data) {
    return request({
      url: "/api/ad/admin/adPastePictureLog/adDriverPhotoRewardLog",
      method: 'post',
      data: data
    });
  },
  // 更新上画记录的备注图
  /**
   *
   * {
      "approvalAdminId": 0,
      "approvalFail": "string",
      "approvalStatus": 0,
      "busCode": 0,
      "createdOn": "2020-05-24T04:42:14.267Z",
      "createdUserId": 0,
      "createdUserType": 0,
      "isDelete": true,
      "pictureId": 0,
      "remarksId": 0,
      "remarksPath": "string",
      "remarksPathHttpPath": "string",
      "remarksTxt": "string"
    }
    * @param data
   */
  updateAdRemark: function updateAdRemark(_ref) {
    var remarksId = _ref.remarksId,
      approvalStatus = _ref.approvalStatus,
      approvalFail = _ref.approvalFail;
    return request({
      url: "/api/ad/admin/adRemark",
      method: 'post',
      data: {
        remarksId: remarksId,
        approvalFail: approvalFail,
        approvalStatus: approvalStatus
      }
    });
  },
  // 查询司机领取红包记录,每条上画记录可能会有多个领取红包的记录，但是最多有一个成功的记录，（一旦提现失败，司机就重新提交申请，也就有新的记录了）
  getAdDriverReceiveGiftMoneyLogs: function getAdDriverReceiveGiftMoneyLogs(params) {
    return request({
      url: "/api/ad/admin/adDriverReceiveGiftMoneyLogs",
      method: 'get',
      params: params
    });
  },
  // 获取承包公司列表
  getCompanyLists: function getCompanyLists(params) {
    return request({
      url: '/api/ad/admin/companies',
      method: 'get',
      params: params
    });
  },
  // 删除项目列表中的某个画面
  deletePicture: function deletePicture(id) {
    return request({
      url: "/api/ad/admin/AdPictureType/".concat(id),
      method: 'delete'
    });
  },
  // 查看导出上画图片的任务
  getPictureHistoryList: function getPictureHistoryList(params) {
    return request({
      url: '/api/ad/admin/adExportTasks',
      method: 'get',
      params: params
    });
  },
  // 查看导出上画图片的任务
  getPictureHistoryListItem: function getPictureHistoryListItem(params) {
    return request({
      url: '/api/ad/admin/adExportTask',
      method: 'get',
      params: params
    });
  },
  // 导出运维人员
  downPicturePeople: function downPicturePeople(params) {
    return request({
      url: '/api/ad/admin/adPastePictureLog/exportPastedAdminName',
      method: 'post',
      params: params
    });
  },
  // 判断图片是否有水印
  checkPictureWater: function checkPictureWater(params) {
    return request({
      url: '/api/ad/admin/adPastePictureLog/imageWatermark',
      method: 'post',
      params: params
    });
  },
  // 车身广告导出图片
  downCarPicture: function downCarPicture(params) {
    return request({
      url: '/api/ad/admin/exportRemarksPictures/v2',
      method: 'post',
      params: params
    });
  },
  getVehiclePastePictureInstallments: function getVehiclePastePictureInstallments(params) {
    return request({
      url: "/api/ad/admin/vehiclePastePictureInstallments",
      method: 'get',
      params: params
    });
  },
  updateRemarksInstallment: function updateRemarksInstallment(params) {
    return request({
      url: "/api/ad/admin/updateRemarksInstallment",
      method: 'post',
      params: params
    });
  },
  deleteAdMonitorVehicle: function deleteAdMonitorVehicle(params) {
    return request({
      url: "/api/ad/admin/adMonitorVehicle/".concat(params),
      method: 'delete'
    });
  },
  // 广告车辆管理
  advertisementVehicle: function advertisementVehicle(params) {
    return request({
      url: '/api/ad/admin/vehicle/list',
      method: 'get',
      params: params
    });
  },
  // 广告车辆管理修改
  advertisementVehicleEdit: function advertisementVehicleEdit(data) {
    return request({
      url: '/api/ad/admin/vehicle',
      method: 'put',
      data: data
    });
  },
  // 根据id查询广告车辆管理的单个车 /api/ad/admin/vehicle/{vehicleId}
  advertisementVehicleSingle: function advertisementVehicleSingle(params) {
    return request({
      url: "/api/ad/admin/vehicle/".concat(params),
      method: 'get'
      // params
    });
  },
  //司机奖励列表
  getDriverRewardList: function getDriverRewardList(params) {
    return request({
      url: "/api/ad/admin/outbudget/driver-reward",
      method: 'post',
      params: params,
      data: params
    });
  }
};
export default api;