import request from '@/utils/request';
var testApi = {
  /**
   * 获取在线司机列表
   * @param params
   * @returns {AxiosPromise}
   */
  getOnlineDrivers: function getOnlineDrivers(params) {
    return request({
      url: "api/admin/driver/queryDriverPosition",
      method: 'get',
      params: params
    });
  }
};
export default testApi;