import request from '@/utils/request';
var api = {
  loadStoreOrderList: function loadStoreOrderList(params) {
    return request({
      url: "/api/admin/driverhome/store-order/list",
      method: 'post',
      data: params
    });
  }
};
export default api;