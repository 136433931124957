import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import * as _ from 'lodash';
export default {
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    dropdownlist: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    var _this$item = this.item,
      name = _this$item.name,
      isOldPerson = _this$item.isOldPerson,
      isDriver = _this$item.isDriver;
    var fromData = {
      name: name,
      isOldPerson: isOldPerson,
      isDriver: isDriver
    };
    return {
      moment: moment,
      visible: false,
      elderly: '',
      driver: '',
      fromData: fromData
    };
  },
  watch: {
    item: {
      handler: function handler(val) {
        if (val) {
          var name = val.name,
            isOldPerson = val.isOldPerson,
            isDriver = val.isDriver;
          this.fromData = {
            name: name,
            isOldPerson: isOldPerson,
            isDriver: isDriver
          };
        }
      },
      deep: true
    }
  },
  methods: {
    /**
     * 判断是否为空
     * @param 判断的数据
     * @returns {string|*} 判断结果
     */
    isNull: function isNull(val) {
      if (val !== null && val !== undefined) {
        return val;
      }
      return '';
    },
    /**
     * 分割线
     * @param arr 数组
     * @returns {*} 返回数组
     */
    setArray: function setArray(arr) {
      var code = null;
      var array = [];
      (arr || []).forEach(function (res) {
        array = _.concat(array, res.children);
      });
      for (var item in array) {
        if (code !== array[item].parentCode) {
          code = array[item].parentCode;
        } else {
          array[item]['isDivided'] = false;
        }
      }
      return array;
    },
    /**
     * 分割按键
     * @param val 按键
     * @returns {string} 返回结果
     */
    setIvrkey: function setIvrkey(val) {
      if (val) {
        var list = val.split('-') || [];
        var ivrkey = '';
        for (var item in list) {
          if (item) {
            if (ivrkey) {
              ivrkey += '-' + list[item].split('@')[1];
            } else {
              ivrkey = list[item].split('@')[1];
            }
          }
        }
        return ivrkey;
      }
      return '';
    },
    /**
     * 处理时间
     */
    isDate: function isDate(time) {
      var days = moment(new Date().valueOf()).diff(time, 'days');
      if (days === 0) {
        return moment(time).calendar();
      } else {
        if (days < 2) {
          return moment(time).subtract(1, 'days').calendar();
        } else {
          return moment(time).format('YYYY/MM/DD');
        }
      }
    },
    /**
     * 处理通话时间
     * @param begin 开始时间
     * @param end 结束时间
     */
    setDuration: function setDuration(begin, end) {
      if (begin && end) {
        return moment.utc(end - begin).format('HH:mm:ss');
      }
      return '';
    },
    /**
     * 标记事件
     * @param command
     */
    handleCommand: function handleCommand(command) {
      var item = this.item;
      if (command === '1') {
        this.$router.push({
          path: '/monitorDispatch/PlaceOrder',
          query: {
            callno: item.callno
          }
        });
      }
      var params = {
        id: item.id,
        tagging: command
      };
      this.$emit('handleCommand', params);
    },
    /**
     * 编辑事件
     * @param name
     */
    editorName: function editorName() {
      var item = this.fromData;
      var params = {
        mobile: this.item.callno,
        name: item.name,
        isOldPerson: item.isOldPerson ? true : false,
        isDriver: item.isDriver ? true : false
      };
      this.$emit('editorName', {
        params: params,
        id: this.item.id
      });
      this.visible = false;
    },
    setRecord: function setRecord() {
      var item = this.item;
      var params = {
        drawer: true,
        data: item
      };
      this.$store.commit('recordsDrawer/DRAWER_STATE', params);
    }
  },
  filters: {
    onDealState: function onDealState(type) {
      var list = {
        'dealing': '已接听',
        'notDeal': '振铃未接听',
        'leak': 'ivr放弃',
        'queueLeak': '排队放弃',
        'blackList': '黑名单',
        'voicemail': '留言'
      };
      return list[type];
    }
  }
};