import request from "@/utils/request";
var api = {
  // 后台下单
  createOrder: function createOrder(params) {
    return request({
      url: "/api/admin/order/dispatch/createOrder",
      method: "post",
      data: params
    });
  },
  // 获取周围车辆
  circleSearchByLoad: function circleSearchByLoad(params) {
    return request({
      url: "/api/admin/order/dispatch/circleSearchByLoadV3",
      method: "get",
      params: params
    });
  },
  // 取消下单
  cancelOrder: function cancelOrder(params) {
    return request({
      url: "/api/admin/order/dispatch/cancelOrder",
      method: "post",
      data: params
    });
  },
  // 获取订单已推送司机信息
  queryPushOrderInfo: function queryPushOrderInfo(params) {
    return request({
      url: "/api/admin/order/dispatch/queryPushOrderInfo",
      method: "get",
      params: params
    });
  },
  // 通过短信推送订单给司机
  pushOrderMessageToDriver: function pushOrderMessageToDriver(params) {
    return request({
      url: "/api/admin/order/dispatch/pushOrderMessageToDriver",
      method: "post",
      data: params
    });
  },
  // 获取未推送短信的空车的司机列表
  circleSearchWithoutNoPush: function circleSearchWithoutNoPush(params) {
    return request({
      url: "/api/admin/order/dispatch/circleSearchWithoutNoPush",
      method: "get",
      params: params
    });
  },
  // 获取后台订单统计信息
  currentCityStats: function currentCityStats(params) {
    return request({
      url: "/api/admin/order/dispatch/currentCityStats",
      method: "get",
      params: params
    });
  },
  // 获取未推送短信的空车的司机列表
  circleSearchWithPushOrNotLoad: function circleSearchWithPushOrNotLoad(params) {
    return request({
      url: "/api/admin/order/dispatch/circleSearchWithPushOrNotLoad",
      method: "get",
      params: params
    });
  },
  queryDriverPushInfo: function queryDriverPushInfo(params) {
    // 查询单辆车的推送信息
    return request({
      url: "/api/admin/order/dispatch/queryDriverPushInfo",
      method: "get",
      params: params
    });
  },
  contactDriver: function contactDriver(params) {
    // 联系司机-添加沟通结果
    return request({
      url: "/api/admin/order/dispatch/contactDriver",
      method: "post",
      data: params
    });
  },
  pushOrderMessageToDrivers: function pushOrderMessageToDrivers(params) {
    // 批量推送短信
    return request({
      url: "/api/admin/order/dispatch/pushOrderMessageToDrivers",
      method: "post",
      data: params
    });
  },
  // 获取订单列表
  queryOrderList: function queryOrderList(params) {
    return request({
      url: "/api/admin/order/dispatch/queryOrderList",
      method: "get",
      params: params
    });
  },
  // 获取待调度的订单列表
  getActiveOrderList: function getActiveOrderList(params) {
    return request({
      url: "/api/admin/order/dispatch/list/v2",
      method: "get",
      params: params
    });
  },
  queryCompleteOrderList: function queryCompleteOrderList(params) {
    // 获取已完成订单列表
    return request({
      url: "/api/admin/order/dispatch/queryCompleteOrderList",
      method: "get",
      params: params
    });
  },
  setTaggingOrder: function setTaggingOrder(params) {
    // 订单标注
    return request({
      url: "/api/admin/order/dispatch/TaggingOrder",
      method: "post",
      data: params
    });
  },
  assignOrder: function assignOrder(params) {
    // 人工指派,传订单ID，司机ID即可
    return request({
      url: "/api/admin/order/dispatch/assignOrder",
      method: "post",
      data: params
    });
  },
  getActiveOrderLine: function getActiveOrderLine(params) {
    // 获取订单监控列表
    return request({
      url: "/api/admin/order/dispatch/getActiveOrderLine",
      method: "get",
      params: params
    });
  },
  setCloseOrderMonitor: function setCloseOrderMonitor(params) {
    // 订单监控-关闭监控
    return request({
      url: "/api/admin/order/dispatch/closeOrderMonitor",
      method: "post",
      data: params
    });
  },
  setReAssignOrder: function setReAssignOrder(params) {
    // 订单监控-重新指派
    return request({
      url: "/api/admin/order/dispatch/reAssignOrder",
      method: "post",
      data: params
    });
  },
  seatsConfirm: function seatsConfirm(params) {
    // 订单监控-坐席确认乘客是否上下车
    return request({
      url: "/api/admin/order/dispatch/seatsConfirm",
      method: "post",
      data: params
    });
  },
  getOrderTrajectoryList: function getOrderTrajectoryList(params) {
    // 订单监控-根据订单ID获取订单轨迹
    return request({
      url: "/api/admin/order/dispatch/getOrderTrajectoryList",
      method: "get",
      params: params
    });
  },
  getVehicleInfo: function getVehicleInfo(params) {
    // 查看车辆信息
    return request({
      url: "/api/admin/order/dispatch/getVehicleInfo",
      method: "get",
      params: params
    });
  },
  getPassagerInfo: function getPassagerInfo(params) {
    // 查看乘客信息(可能为空)
    return request({
      url: "/api/admin/order/dispatch/getPassagerInfo",
      method: "get",
      params: params
    });
  },
  queryCallRecordList: function queryCallRecordList(params) {
    // 查询来电接通列表
    return request({
      url: "/api/admin/order/dispatch/queryCallRecordList",
      method: "get",
      params: params
    });
  },
  updateQimoTagging: function updateQimoTagging(params) {
    // 七陌弹窗，修改标注(传id和tagging字段即可)
    return request({
      url: "/api/admin/order/dispatch/updateQimoTagging",
      method: "post",
      data: params
    });
  },
  updateQimoUser: function updateQimoUser(params) {
    // 七陌弹窗，根据手机号修改用户名（传mobile和name字段）
    return request({
      url: "/api/admin/order/dispatch/updateQimoUser",
      method: "post",
      data: params
    });
  },
  addTrajectoryPosition: function addTrajectoryPosition(params) {
    // 添加游荡车辆
    return request({
      url: "/api/admin/order/dispatch/addTrajectoryPosition",
      method: "get",
      params: params
    });
  },
  sendSelfCallTaxiSms: function sendSelfCallTaxiSms(params) {
    //发送自助叫车短信
    return request({
      url: "/api/admin/order/dispatch/send-call-taxi-sms",
      method: "get",
      params: params
    });
  },
  // 订单列表，查询已开放城市
  queryCityWithCreateOrder: function queryCityWithCreateOrder() {
    return request({
      url: "/api/admin/order/dispatch/queryCityWithCreateOrder",
      method: "get"
    });
  }
};
export default api;