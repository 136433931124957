export default {
  LOST_REFUND_STATUS_UNPAID: 0,
  LOST_REFUND_STATUS_SUCCESS: 1,
  LOST_REFUND_STATUS_PROCESSING: 2,
  LOST_REFUND_STATUS_FAILED: 3,
  LOST_STATUS_UNHANDLED: 0,
  LOST_STATUS_HANDLING: 1,
  LOST_STATUS_HANDLED: 2,
  LOST_UNMATCHED: 0,
  LOST_MATCHED: 1,
  LOST_PAY_STATUS_PAID: 1,
  LOST_PAY_STATUS_UNPAID: 0,
  OWNER_CONFIRM_UNCONFIRMED: 2,
  OWNER_CONFIRM_SUCCESS: 1,
  OWNER_CONFIRM_FAILED: 0,
  REWARD_STATUS_UNRECEIVED: null,
  REWARD_STATUS_UNPAID: 0,
  REWARD_STATUS_PAID: 1,
  REWARD_STATUS_PROCESSING: 2,
  REWARD_STATUS_FAILED: 3,
  OUTLAY_TRADE_CHANEL_WXMPPAY: 1,
  // 公众号支付
  OUTLAY_TRADE_CHANEL_UNIONPAY: 2,
  // 银联支付
  OUTLAY_TRADE_CHANEL_WXREDPACK: 3,
  // 微信红包支付
  OUTLAY_TRADE_CHANEL_WXENTPAY: 4,
  // 微信企业付款到零钱

  SAVE_STATUS_FAILED: 0,
  SAVE_STATUS_SUCCESS: 1,
  BIZ_CODE_LOST_REFUND: 1,
  // 失物查找乘客退款
  BIZ_CODE_LOST_REWARD: 2,
  // 失物查找乘客奖励

  DRIVER_INFO_DEFAULT: 1,
  DRIVER_INFO_HY: 2,
  DRIVER_INFO_GPS: 3,
  ADMIN_USER_ROLE_SUPER_ADMIN: 1,
  // 后台用户角色：超级
  ADMIN_USER_ROLE_DISPATCH_ADMIN: 2,
  // 后台用户角色：调度管理员
  ADMIN_USER_ROLE_FIN_ADMIN: 3,
  // 后台用户角色：财务管理员

  // 营运状态。1. 载客 2.接单 3.空驶 4.停运'
  BIZ_STATUS_1_LOAD: 1,
  BIZ_STATUS_1_ORDER: 2,
  BIZ_STATUS_1_EMPTY: 3,
  BIZ_STATUS_1_STOP: 3,
  // 地图页面 show mode（SM）
  SM_MONITOR: 'm',
  SM_TRACK: 't',
  SM_STICKER: 's',
  SM_CLUSTER: 'c',
  // Monitor Mode 监控模式
  MM_CLUSTERING: 1,
  // 车辆聚集
  MM_MONITOR: 2,
  // 车辆监控
  isLoadText: function isLoadText(isLoad) {
    var s = {
      true: '载客',
      false: '空驶'
    };
    return s[isLoad] || '';
  },
  isLoadTextColor: function isLoadTextColor(isLoad) {
    var s = {
      true: 'blue',
      false: 'gray'
    };
    return s[isLoad] || '';
  },
  bizStatusText: function bizStatusText(bizStatus) {
    var s = {
      1: '载客',
      2: '接单',
      3: '空驶',
      4: '停运'
    };
    return s[bizStatus] || '';
  },
  bizStatusTextColor: function bizStatusTextColor(bizStatus) {
    var s = {
      1: 'blue',
      2: 'orange',
      3: 'red',
      4: 'gray'
    };
    return s[bizStatus] || '';
  },
  vehTrackType: function vehTrackType(t) {
    var m = {
      'ph': '配合组',
      'yj': '迎检组'
    };
    return m[t] || '';
  },
  callTagEchartsName: function callTagEchartsName(echartName) {
    var dict = {
      '乘客叫车': 'callCat',
      '乘客失物查找': 'findLost',
      '乘客咨询': 'consulting',
      '乘客投诉建议': 'userComplaintsSuggestions',
      '司机导航': 'navigation',
      '司机咨询求助': 'help',
      '司机投诉建议': 'driverComplaintsSuggestions',
      '司机反馈信息': 'driverFeedback',
      '无声': 'silent',
      '打错电话': 'wrongCall',
      '断线': 'bolt',
      '企业/单位来电': 'EnterpriseOrUnitCall',
      '其他来电': 'otherCall'
    };
    return dict[echartName] || echartName;
  }
};