var state = {
  searchType: 1
};
var mutations = {
  SEARCH_TYPE: function SEARCH_TYPE(state, data) {
    state.searchType = data;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};