import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import recordsDrawer from '@/components/recordsDrawer';
import { mapActions, mapMutations, mapState } from 'vuex';
import { getToken } from '@/utils/auth';
import { getInfo } from '@/api/user';
import * as _ from 'lodash';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';
import Push from 'push.js';
import moment from 'moment';
import onlinePrompt from "./views/placeOrder/components/onlinePrompt";
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration
export default {
  name: 'App',
  data: function data() {
    return {
      timerId: null,
      intervalId: null,
      websock: null,
      reconnect: true,
      firstTime: false,
      lockReconnect: false,
      // 是否真正建立连接
      timeout: 10 * 1000,
      // 30秒一次心跳
      timeoutObj: null,
      // 心跳心跳倒计时
      serverTimeoutObj: null,
      // 心跳倒计时
      timeoutnum: null,
      // 断开 重连倒计时

      isHidden: false,
      // 窗口显示、最小化变化
      show: false,
      // 决绝通知后显示提示
      notifyList: [],
      // 订单消息提醒
      notifyPermissions: false,
      alertTitle: '你已离线，请检查网络',
      isClosable: false
    };
  },
  components: {
    onlinePrompt: onlinePrompt,
    recordsDrawer: recordsDrawer
  },
  computed: _objectSpread({}, mapState({
    userId: function userId(state) {
      return state.user.userId;
    },
    token: function token(state) {
      return state.user.token;
    },
    permissions: function permissions(state) {
      return state.user.permissions;
    }
  })),
  watch: {
    userId: {
      handler: function handler(val, oldVal) {
        if (val) {
          this.initWebSocket();
        }
      },
      immediate: true
    },
    permissions: function permissions(value) {
      if (value) {
        for (var item in value) {
          if (value[item].val === 'monitoring.map' || value[item].val === 'all') {
            this.notifyPermissions = true;
            return;
          } else {
            this.notifyPermissions = false;
          }
        }
      }
    },
    token: function token(val) {
      if (val === null) {
        if (this.websock) {
          this.stopHeartbeatTimer();
          if (this.timeoutObj) {
            clearInterval(this.timeoutObj);
          }
          if (this.serverTimeoutObj) {
            clearInterval(this.serverTimeoutObj);
          }
          if (this.timeoutnum) {
            clearInterval(this.timeoutnum);
          }
          if (this.notifyList.length > 0) {
            this.notifyList.forEach(function (item) {
              item.close();
            });
            this.notifyList.splice(0, this.notifyList.length);
          }
          this.websock.close();
          this.websock = null;
          // 关闭总线
          this.$off('timeout');
          this.$off('operation');
          this.$off('reconnect');
          this.$off('callPush');
          this.$off('hangupPush');
        }
      }
    }
  },
  created: function created() {
    document.addEventListener('visibilitychange', this.pageHiddenHandler, false); // 监听窗口视野变化
    if (this.userId) {
      this.initWebSocket();
    } else {
      if (getToken()) {
        getInfo(getToken());
      }
    }
    this.$store.dispatch('settings/changeSetting', {
      key: 'fixedHeader',
      value: true
    });
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions({
    getStrokeList: 'placeOrder/getGueryOrderList'
  })), mapMutations({
    increase: 'placeOrder/SWITCH_TAB_STATE'
  })), {}, {
    pageHiddenHandler: function pageHiddenHandler() {
      this.isHidden = this.getHidden();
      // console.log('监听窗口变化：', this.isHidden)
      return this.isHidden;
    },
    /*
      * 判断浏览器是否显示、最小化
      * */
    getHidden: function getHidden() {
      // 判断当前页面是否处于活动状态
      var prefixs = ['webkit', 'moz', 'mos', 'o'];
      if ('hidden' in document) {
        return document.hidden;
      }
      for (var i = 0; i < prefixs.length; i++) {
        if ("".concat(prefixs[i], "Hidden") in document) {
          return document["".concat(prefixs[i], "Hidden")];
        }
      }
      return null;
    },
    // 推送弹框消息
    pushMessage: function pushMessage(message, orderType) {
      var _this2 = this;
      var icon = process.env.VUE_APP_NEW_ORDER_NOTICE_ICON_URL;
      Push.create("\u65B0\u8BA2\u5355  ".concat(orderType), {
        body: message,
        icon: icon,
        requireInteraction: true,
        timeout: 600000,
        silent: true,
        onClick: function onClick() {
          _this2.$router.push('/monitorDispatch/PlaceOrder');
          window.focus();
        },
        onError: function onError(e) {
          console.error('通知报错：', e);
        }
      });
    },
    initWebSocket: function initWebSocket() {
      console.log('初始化websocket');
      // http: -> ws:, https: -> wss:
      if (this.token !== null) {
        var socketProtocol = document.location.protocol.replace(/http/, 'ws');
        var wsUri = "".concat(socketProtocol).concat(process.env.VUE_APP_BASE_API, "/api/admin/webSocket?token=").concat(getToken());
        this.websock = new WebSocket(wsUri);
        this.websock.onmessage = this.wsOnMessage;
        this.websock.onopen = this.wsOnOpen;
        this.websock.onerror = this.wsOnError;
        this.websock.onclose = this.wsOnClose;
      }
    },
    startHeartbeatTimer: function startHeartbeatTimer() {
      var _this = this;
      this.intervalId = setInterval(function () {
        if (_this.websock) {
          _this.websock.send('{}');
        }
      }, 5000);
    },
    stopHeartbeatTimer: function stopHeartbeatTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    reconnec: function reconnec() {
      // 重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      // 没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        // 新连接
        that.initWebSocket();
        that.lockReconnect = false;
      }, 5000);
    },
    reset: function reset() {
      // 重置心跳
      var that = this;
      // 清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      // 重启心跳
      that.start();
    },
    start: function start() {
      var _this3 = this;
      // 开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.websock.readyState === 1) {
          // 如果连接正常
          self.websock.send('heartCheck');
          if (_this3.isClosable) {
            _this3.isClosable = false;
          }
        } else {
          // 否则重连
          self.reconnec();
        }
        self.serverTimeoutObj = setTimeout(function () {
          // 超时关闭
          self.websock.close();
        }, self.timeout);
      }, self.timeout);
    },
    toRequest: function toRequest() {
      this.$EventBus.$emit('operation');
    },
    wsOnOpen: function wsOnOpen() {
      if (this.socket) {
        this.socket.setRequestHeader('token', this.token);
      }
      // 开启心跳
      this.start();
      // 重新连接
      if (this.firstTime) {
        _.debounce(this.toRequest, 2000);
      }
    },
    wsOnError: function wsOnError() {
      // this.initWebSocket()
      // 重连
      if (this.token !== null) {
        this.reconnec();
        if (this.isClosable) {
          this.alertTitle = '你已离线，重连中.....';
        }
      }
    },
    wsOnMessage: function wsOnMessage(e) {
      var _this = this;
      try {
        var _JSON$parse = JSON.parse(e.data),
          type = _JSON$parse.type,
          data = _JSON$parse.data;
        // console.log('----类型---',type);
        // console.log('----数据---',data);
        switch (type) {
          case '1001':
            // 心跳
            break;
          case 'LOST_NEW_VIP_ORDER':
            {
              var lost = this.$can('SHOWMENU', 'lost');
              console.log('----失物查找推送-权限----', lost);
              if (!lost) {
                console.log('---没权限---');
                return;
              } else {
                console.log('---有权限---');
              }
              this.$notify({
                title: '失物查找VIP服务',
                message: data,
                type: 'success',
                duration: 0
              });
              NProgress.start();
              this.$store.dispatch('lost/reloadLostOrders').then(function (res) {}).finally(function () {
                NProgress.done();
              });
            }
            break;
          case 'WX_REFUND_RESULT':
            {
              // this.$store.commit('lost/WX_REFUND_RESULT', data)
              var lostId = data.lostId,
                status = data.status,
                statusText = data.statusText;
              // 刷新
              this.$store.dispatch('lost/loadRefundInfo', lostId).catch(function (reason) {
                console.error(reason);
              });
            }
            break;
          case 'LOST_NEW_ORDER':
            {
              // this.$notify({ title: '失物查找新订单', message: '有一个新订单，数据已经刷新，请查看。', type: 'success', duration: 0 })

              // this.$store.commit('lost/ADD_ORDER', data)
              // start progress bar
              NProgress.start();
              this.$store.dispatch('lost/reloadLostOrders').then(function (res) {}).finally(function () {
                NProgress.done();
              });
              this.$store.dispatch('lost/loadStatistics');
            }
            break;
          case 'BACKSTAGE_ORDER_STATUS':
            {
              // BACKSTAGE_ORDER_STATUS
              _this.$EventBus.$emit('reconnect', data);
              // 开启消息提示
              if (data.mainStatus) {
                _this.generateNotify(data);
              }
            }
            break;
          case 'ORDER_AOTU_CANCEL':
            {
              // 自动取消
              _this.$EventBus.$emit('operation', data);
              // 开启消息提示
              if (data.mainStatus) {
                _this.generateNotify(data);
              }
            }
            break;
          case 'THREE_MIN_NORESPOSE':
            {
              // 三分钟无响应事件
              _this.$EventBus.$emit('timeout', data, 3);
            }
            break;
          case 'ADMIN_ORDER_CREATE':
            {
              // 后台订单-创建订单
              _this.$EventBus.$emit('operation', data);
              var monitoringMap = this.$can('SHOWMENU', 'monitoring.map');
              console.log('----订单推送-权限----', monitoringMap);
              if (!monitoringMap) {
                console.log('---没权限---');
                return;
              } else {
                console.log('---有权限---');
              }

              // 开启消息提示
              if (data.mainStatus) {
                _this.generateNotify(data);
              }
              var url = window.location.href;
              var item = url.indexOf('/#/');
              var params = url.slice(item, url.length);
              console.log('ADMIN_ORDER_CREATE params：', params);

              // 除了后台下单都执行
              if (this.isHidden || data.source !== 4) {
                var orderTypeTitle = data.type === '0' ? '实时订单' : '预约订单';
                var time = data.createdOn ? moment(data.createdOn).format('YYYY/MM/DD HH:mm:ss') : '';
                // const message = `乘客：${data.passenger.mobile}  城市：${data.origin.city}                ${data.origin.address}  -->  ${data.dest.address}                       时间：${time}`
                var message = "\u4E58\u5BA2\uFF1A".concat(data.passenger.mobile, "  \u57CE\u5E02\uFF1A").concat(data.origin.city, "\n").concat(data.origin.address, "  -->  ").concat(data.dest.address, "\n\u65F6\u95F4\uFF1A").concat(time);
                this.pushMessage(message, orderTypeTitle);
              }
            }
            break;
          case 'ADMIN_ORDER_CANCEL':
            {
              // 后台订单-取消订单
              _this.$EventBus.$emit('operation', data);
              // 开启消息提示
              if (data.mainStatus) {
                _this.generateNotify(data);
              }
            }
            break;
          case 'ADMIN_ORDER_MONITOR':
            {
              // 后台订单-取消订单
              _this.$EventBus.$emit('minitor', data);
            }
            break;
          case 'ADMIN_LINK':
            // 来电
            _this.$EventBus.$emit('callPush', data);
            break;
          case 'ADMIN_UNLINK':
            // 通话挂断
            _this.$EventBus.$emit('hangupPush', data);
            break;
          default:
            console.log('未处理 Websocket 通知：type=' + type);
            break;
        }
      } catch (e) {
        console.error(e);
      }
      // 收到服务器信息，心跳重置
      this.reset();
    },
    wsOnClose: function wsOnClose(e) {
      // 关闭
      console.log('断开连接', e);
      this.firstTime = true;
      // 重连
      if (this.token !== null) {
        this.reconnec();
      }
      if (!this.isClosable && this.token) {
        this.alertTitle = '你已离线，请检查网络';
        this.isClosable = true;
      }
    },
    websocketSend: function websocketSend(data) {
      this.websock.send(data);
    },
    /**
     * 生成订单消息提醒
     * @param msg
     */
    generateNotify: function generateNotify(msg) {
      if (this.notifyPermissions) {
        var createElement = this.$createElement;
        if (this.notifyList.length >= 3) {
          this.notifyList[0].close();
          this.notifyList.splice(0, 1);
        }
        var notify = this.$notify({
          dangerouslyUseHTMLString: true,
          message: createElement('onlinePrompt', {
            props: {
              da: msg
            },
            on: {
              view: this.viewNotify
            }
          }),
          position: 'bottom-left',
          duration: 0
        });
        this.notifyList.push(notify);
      }
    },
    /**
     * 处理消息提醒
     * @param value 消息内容
     */
    viewNotify: function viewNotify(value) {
      var paths = '/monitorDispatch/PlaceOrder';
      var params = {};
      switch (value.mainStatus) {
        case '2':
          params = {
            activeName: 'ongoingObj',
            orderId: value.id
          };
          break;
        case '3':
          params = {
            addressType: value.addressType,
            status: 3,
            orderId: value.id,
            activeName: 'completedObj'
          };
          if (params.addressType === -1) {
            delete params.addressType;
          }
          break;
        case '4':
          paths = '/order/orderList';
          params = {
            id: value.id
          };
          break;
        default:
          params = {
            activeName: 'waitingOrder'
          };
      }
      params.cityCnState = value.addressType;
      this.$router.push({
        path: paths,
        query: _objectSpread({}, params)
      });
    }
  }),
  beforeDestroy: function beforeDestroy() {
    if (this.websock) {
      this.websock.close();
    }
    // 关闭总线
    this.$off('timeout');
    this.$off('operation');
    this.$off('reconnect');
    this.$off('callPush');
    this.$off('hangupPush');
    document.removeEventListener('visibilitychange', this.pageHiddenHandler); // 监听窗口视野变化
  }
};