import _toConsumableArray from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _objectSpread from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import defaultItem from "./defaultItem";
import api from '@/api/dispatch';
import dictApi from "@/api/dict";
import { mapState } from 'vuex';
export default {
  components: {
    defaultItem: defaultItem
  },
  props: {},
  data: function data() {
    return {
      moment: moment,
      state: 1,
      visible: false,
      item: {},
      dropdownlist: [],
      callTime: 0
    };
  },
  computed: _objectSpread({}, mapState({
    token: function token(state) {
      return state.user.token;
    }
  })),
  watch: {
    token: function token(val) {
      if (val === null) {
        window.sessionStorage.removeItem('CALL');
        window.sessionStorage.removeItem('CALL_DATA');
      }
    }
  },
  mounted: function mounted() {
    // 订单超时
    this.init();
    var _this = this;
    this.$EventBus.$on('callUpdateCall', function (msg) {
      // A发送来的消息
      if (_this.item.id === msg.id) {
        _this.item = msg;
        window.sessionStorage.setItem('CALL_DATA', JSON.stringify(msg));
      }
    });
  },
  destroyed: function destroyed() {
    clearInterval(this.time);
  },
  methods: {
    /**
     * 初始化
     */
    init: function init() {
      var _this2 = this;
      var _this = this;
      this.$EventBus.$on('callPush', function (msg) {
        // A发送来的消息
        _this.$nextTick(function () {
          _this.state = 3;
          _this.item = _objectSpread({}, msg);
        });
        _this.visible = true;
        _this.setTime();
      });
      this.$EventBus.$on('hangupPush', function (msg) {
        // A发送来的消息
        _this.state = 1;
        clearInterval(_this.time);
        _this.visible = false;
        _this2.callTime = 0;
        window.sessionStorage.removeItem('CALL');
        window.sessionStorage.removeItem('CALL_DATA');
      });
      if (window.sessionStorage.getItem('CALL')) {
        var data = JSON.parse(window.sessionStorage.getItem('CALL'));
        this.state = data.state;
        this.callTime = data.callTime;
        this.setTime();
      }
      if (window.sessionStorage.getItem('CALL_DATA')) {
        var _data = JSON.parse(window.sessionStorage.getItem('CALL_DATA'));
        this.item = _objectSpread({}, _data);
      }
      // 加载标签数据
      dictApi.queryQimoDictList().then(function (val) {
        var status = val.status,
          data = val.data;
        if (status === '0') {
          if (Array.isArray(data)) {
            _this2.dropdownlist = _toConsumableArray(data);
          }
        }
      }).catch(function (err) {
        return console.log(err);
      });
    },
    /**
     * 开启来电列表
     */
    open: function open(state) {
      this.$store.commit('drawer/DRAWER_STATE', true);
      if (state === 3) {
        this.visible = !this.visible;
      }
    },
    /**
     * 开启计时器
     */
    setTime: function setTime() {
      var _this = this;
      if (this.time) {
        clearInterval(this.time);
      }
      this.time = setInterval(function () {
        _this.callTime += 1000;
        var params = {
          state: _this.state,
          callTime: _this.callTime
        };
        window.sessionStorage.setItem('CALL', JSON.stringify(params));
        window.sessionStorage.setItem('CALL_DATA', JSON.stringify(_this.item));
      }, 1000);
    },
    /**
     * 处理标记
     * @param params 处理标记结果 {id, command}
     */
    handleCommand: function handleCommand(params) {
      var _this3 = this;
      api.updateQimoTagging(params).then(function (val) {
        var status = val.status;
        if (status === '0') {
          _this3.$message({
            message: '标记成功',
            type: 'success'
          });
          var paras = {
            id: _this3.item.id
          };
          _this3.getData(paras);
        }
      }).catch(function (err) {
        _this3.$message.error(err || '操作失败');
      });
    },
    /**
     * 编辑名称
     * @param params 处理标记结果 {id, name}
     */
    editorName: function editorName(params) {
      var _this4 = this;
      api.updateQimoUser(params).then(function (val) {
        var status = val.status;
        if (status === '0') {
          _this4.$message({
            message: '编辑成功',
            type: 'success'
          });
          var paras = {
            id: _this4.item.id
          };
          _this4.getData(paras);
        }
      }).catch(function (err) {
        _this4.$message.error(err || '操作失败');
      });
    },
    /**
     * 请求数据
     * @param params {Object} 请求参数
     */
    getData: function getData(params) {
      var _this5 = this;
      var _this = this;
      api.queryCallRecordList(params).then(function (val) {
        var status = val.status,
          data = val.data;
        if (status === '0') {
          if (data.recordExpands !== null && data.recordExpands !== undefined) {
            _this5.item = data.recordExpands[0];
            if (_this5.item) {
              window.sessionStorage.setItem('CALL_DATA', JSON.stringify(_this5.item));
              _this.$EventBus.$emit('updateCall', data.recordExpands[0]);
            }
          }
        }
      }).catch(function (err) {
        return console.log(err);
      });
    }
  }
};