var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "currentCall" }, [
    _vm.state === 1
      ? _c("div", { staticClass: "online-Rate-message" }, [
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.open()
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("svg-icon", {
                    staticStyle: { "margin-left": "5px", "font-size": "16px" },
                    attrs: { "icon-class": "callInformNo" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "message-address",
                  staticStyle: {
                    color: "#474C58",
                    "padding-left": "5px",
                    "font-weight": "normal",
                  },
                },
                [_vm._v("当前无来电！")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  staticClass: "message-i",
                },
                [_c("i", { staticClass: "el-icon-arrow-up" })]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: true,
                      expression: "true",
                    },
                  ],
                  staticClass: "message-i",
                },
                [_c("i", { staticClass: "el-icon-arrow-down" })]
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.state === 3
      ? _c(
          "div",
          {
            staticClass: "online-Rate-message",
            style: { "background-color": _vm.state === 3 ? "#469CFF" : "" },
          },
          [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.open(_vm.state)
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("svg-icon", {
                      staticStyle: {
                        "margin-left": "5px",
                        "font-size": "16px",
                        "margin-right": "5px",
                      },
                      attrs: { "icon-class": "incall" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "message-address",
                    staticStyle: { color: "#FFFFFF" },
                  },
                  [
                    _vm._v(
                      "通话中 " +
                        _vm._s(_vm.moment.utc(_vm.callTime).format("HH:mm:ss"))
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.visible,
                        expression: "!visible",
                      },
                    ],
                    staticClass: "message-i",
                    staticStyle: { color: "#FFFFFF" },
                  },
                  [_c("i", { staticClass: "el-icon-arrow-up" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.visible,
                        expression: "visible",
                      },
                    ],
                    staticClass: "message-i",
                    staticStyle: { color: "#FFFFFF" },
                  },
                  [_c("i", { staticClass: "el-icon-arrow-down" })]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "call-popover",
      },
      [
        _c("defaultItem", {
          staticStyle: { padding: "0" },
          attrs: { item: _vm.item, dropdownlist: _vm.dropdownlist },
          on: { handleCommand: _vm.handleCommand, editorName: _vm.editorName },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }