var state = {
  url: process.env.VUE_APP_BASE_API,
  head: '',
  clusterConfig: {
    minPoints: 10,
    meters: 10
  }
};
var mutations = {
  URL: function URL(state, data) {
    state.url = data;
  },
  SET_HEAD: function SET_HEAD(state, data) {
    state.head = data;
  },
  CLUSTER_CONFIG: function CLUSTER_CONFIG(state, data) {
    state.clusterConfig = data;
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};