import request from '@/utils/request';
var api = {
  // 车辆在线率统计
  getVehicleOnlineCount: function getVehicleOnlineCount(params) {
    return request({
      url: "/api/admin/order/getVehicleOnlineCount",
      method: 'get',
      params: params
    });
  },
  // 获取需要审核的司机数量与订单数量
  getOrderCount: function getOrderCount(params) {
    return request({
      url: "/api/admin/order/getOrderCount",
      method: 'get',
      params: params
    });
  },
  getRentCount: function getRentCount(params) {
    return request({
      url: '/api/admin/driver/transfer-article/get-wait-activity-cont',
      method: 'get',
      params: params
    });
  },
  getOffWxList: function getOffWxList(params) {
    return request({
      url: "/api/admin/driver/OffWxList",
      method: 'get',
      params: params
    });
  },
  getOnWxList: function getOnWxList(params) {
    return request({
      url: "/api/admin/driver/OnWxList",
      method: 'get',
      params: params
    });
  }
};
export default api;