import request from '@/utils/request';
var driverApi = {
  getStatistics: function getStatistics(startTimeStr, endTimeStr) {
    return request({
      url: "api/admin/report/order-statistics",
      method: 'get',
      params: {
        startTimeStr: startTimeStr,
        endTimeStr: endTimeStr
      }
    });
  },
  resetPassword: function resetPassword(params) {
    return request({
      url: "api/admin/driver/password",
      method: 'put',
      params: params
    });
  },
  loadOrderStatList: function loadOrderStatList(params) {
    return request({
      url: "api/admin/driver/orderstat",
      method: 'get',
      params: params
    });
  },
  loadCompanies: function loadCompanies() {
    return request({
      url: "api/admin/companies",
      method: 'get',
      params: null
    });
  },
  // 获取公司列表
  getCompany: function getCompany(data) {
    return request({
      url: "/api/admin/company",
      method: 'get',
      params: data
    });
  },
  // 查询全部司机信息
  getDriverAll: function getDriverAll(params) {
    return request({
      url: '/api/admin/driver/all',
      method: 'get',
      params: params
    });
  },
  // 获取路线开通的城市
  getLineCity: function getLineCity() {
    return request({
      url: '/api/admin/driverReg/allCity',
      method: 'get'
    });
  },
  // 运维助手
  getAppRoles: function getAppRoles() {
    return request({
      url: '/api/admin/user/appRoles',
      method: 'get'
    });
  },
  // 新司机列表操作日志
  getOptionsLog: function getOptionsLog(params) {
    return request({
      url: '/api/admin/driver/operateLog',
      method: 'get',
      params: params
    });
  }
};
export default driverApi;