var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "div",
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: {
              id: "hamburger-container",
              "is-active": _vm.sidebar.opened,
            },
            on: { toggleClick: _vm.toggleSideBar },
          }),
          _vm._v(" "),
          _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c("search", {
            staticClass: "hidden-lg right-menu-item ",
            attrs: { id: "header-search" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "hidden-lg-and-down" }, [
            _vm.showButton && _vm.$can("SHOWMENU", "monitoring.map")
              ? _c(
                  "div",
                  { staticClass: " notice " },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: { click: _vm.openNotice },
                      },
                      [_vm._v("开启桌面通知")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.$can("SHOWMENU", "callmgr") ? _c("current-call") : _vm._e(),
          _vm._v(" "),
          _vm.$can("SHOWMENU", "monitoring.map")
            ? [
                _c(
                  "div",
                  {
                    attrs: { slot: "reference" },
                    on: {
                      click: function ($event) {
                        return _vm.toRourter()
                      },
                    },
                    slot: "reference",
                  },
                  [
                    _c("div", { staticClass: "onlinerate" }, [
                      _c(
                        "div",
                        { ref: "message" },
                        [
                          _c(
                            "transition-group",
                            {
                              attrs: {
                                name: "message-fade",
                                appear: "",
                                tag: "div",
                              },
                            },
                            [
                              _vm._l(_vm.rollingList, function (value, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "message-wifi" },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "wifi" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "message-address",
                                      style: {
                                        color:
                                          value.onlineRate === 0
                                            ? "#D65849"
                                            : "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(value.areaName) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "message-digital",
                                      style: {
                                        color: _vm.onlineRate(
                                          value.onlineRate
                                            ? value.onlineRate
                                            : 0
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            value.onlineRate
                                              ? value.onlineRate
                                              : 0
                                          ) +
                                          "%\n                  "
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("SHOWMENU", "drivermgr.account")
            ? _c(
                "div",
                {
                  staticClass: "right-menu-message",
                  on: { click: _vm.toRouter },
                },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { content: "司机账号审核", "open-delay": 300 } },
                    [
                      _c(
                        "el-badge",
                        {
                          staticClass: "item",
                          attrs: {
                            value: _vm.driverNoRegCount,
                            hidden: _vm.driverNoRegCount > 0 ? false : true,
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { "font-size": "32px" },
                            attrs: { "icon-class": "driver-audit" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("SHOWMENU", "monitoring.map")
            ? _c(
                "div",
                {
                  staticClass: "right-menu-message",
                  on: {
                    click: function ($event) {
                      return _vm.toRouter("订单通知")
                    },
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { content: "订单调度", "open-delay": 300 } },
                    [
                      _c(
                        "el-badge",
                        {
                          staticClass: "item",
                          attrs: {
                            value: _vm.orderCount,
                            hidden: _vm.orderCount > 0 ? false : true,
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { "font-size": "32px" },
                            attrs: { "icon-class": "order-inform" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$can("SHOWMENU", "article.post")
            ? _c(
                "div",
                {
                  staticClass: "right-menu-message",
                  on: {
                    click: function ($event) {
                      return _vm.toRouter("招租审核")
                    },
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { content: "招租审核", "open-delay": 300 } },
                    [
                      _c(
                        "el-badge",
                        {
                          staticClass: "item",
                          attrs: {
                            value: _vm.rentCount,
                            hidden: _vm.rentCount > 0 ? false : true,
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { "font-size": "32px" },
                            attrs: { "icon-class": "rent-list" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.device !== "mobile"
            ? [
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                }),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("navbar.size"),
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                false
                  ? _c("lang-select", {
                      staticClass: "right-menu-item hover-effect",
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item",
              attrs: { trigger: "click", size: "medium" },
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c("svg-icon", {
                    staticClass: "user-avatar1",
                    attrs: { "icon-class": "user" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.name))]),
                  _vm._v(" "),
                  false
                    ? _c("img", {
                        staticClass: "user-avatar",
                        attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/index" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.profile")) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.dashboard")) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "开启桌面通知方法",
            visible: _vm.dialogVisible,
            width: "40%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("h4", { staticStyle: { color: "red" } }, [
                      _vm._v("1、谷歌浏览器"),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("第一步：点击网站链接左边的黑色小锁")]),
                    _vm._v(" "),
                    _c("p", [_vm._v("第二步：点击通知选项")]),
                    _vm._v(" "),
                    _c("p", [_vm._v("第三步：允许")]),
                    _vm._v(" "),
                    _c("p", { staticStyle: { color: "red" } }, [
                      _vm._v("例如："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c(
                          "viewer",
                          { attrs: { options: _vm.optionsViewer } },
                          [
                            _c("img", {
                              staticStyle: { border: "1px dotted grey" },
                              attrs: {
                                src: require("@/icons/img/chromeNotification.png"),
                                width: "100%",
                                height: "auto",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("h4", { staticStyle: { color: "red" } }, [
                      _vm._v("2、360浏览器"),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("第一步：选择设置")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "第二步：选择高级设置-->网页设置-->网页内容高级设置"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("第三步：选择通知-->通知外例情况-->点击删除"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("第四步：返回后台点击开启通知-->弹窗选择允许"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticStyle: { color: "red" } }, [
                      _vm._v("例如："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-flow": "row wrap",
                        },
                      },
                      [
                        _c(
                          "viewer",
                          { attrs: { options: _vm.optionsViewer } },
                          _vm._l(_vm.second, function (item, index) {
                            return _c("img", {
                              key: index,
                              staticStyle: {
                                border: "1px dotted grey",
                                margin: "5px",
                              },
                              attrs: {
                                src: item,
                                width: "45%",
                                height: "auto",
                              },
                            })
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 34 } }, [
                    _c("h4", { staticStyle: { color: "red" } }, [
                      _vm._v("3、QQ浏览器"),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("第一步：选择设置")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("第二步：选择高级-->选择隐私与安全-->内容设置"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "第三步：选择通知-->通知外例情况-->点击删除-->点击完成"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("第四步：返回后台点击开启通知-->弹窗选择允许"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticStyle: { color: "red" } }, [
                      _vm._v("例如："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-flow": "row wrap",
                        },
                      },
                      [
                        _c(
                          "viewer",
                          { attrs: { options: _vm.optionsViewer } },
                          _vm._l(_vm.qq, function (item, index) {
                            return _c("img", {
                              key: index,
                              staticStyle: {
                                border: "1px dotted grey",
                                margin: "5px",
                              },
                              attrs: {
                                src: item,
                                width: "30%",
                                height: "auto",
                              },
                            })
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }