var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("el-alert", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isClosable,
            expression: "isClosable",
          },
        ],
        attrs: {
          title: _vm.alertTitle,
          type: "warning",
          description: "离线后，你将接收不到订单消息和失物查找消息，请检查网络",
          "show-icon": "",
        },
      }),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("records-drawer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }