var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-menu" },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { staticStyle: { height: "calc(100% - 30px)" } },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo",
              staticStyle: { height: "100%" },
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "active-text-color": _vm.variables.menuActiveText,
                router: true,
              },
            },
            [
              _vm._l(_vm.permission_routes, function (route, index) {
                return [
                  _vm.hasOneShowingChild(route.children, route) &&
                  (!_vm.onlyOneChild.children ||
                    _vm.onlyOneChild.noShowingChildren) &&
                  !route.alwaysShow
                    ? [
                        _vm.onlyOneChild.meta && !route.hidden
                          ? _c(
                              "el-menu-item",
                              {
                                key: index,
                                attrs: {
                                  index: _vm.onlyOneChild.path
                                    ? route.path + "/" + _vm.onlyOneChild.path
                                    : route.path,
                                },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "menu-icon",
                                  staticStyle: { "margin-right": "16px" },
                                  attrs: {
                                    "icon-class":
                                      _vm.onlyOneChild.meta.icon ||
                                      (route.meta && route.meta.icon),
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.generateTitle(
                                          _vm.onlyOneChild.meta.title
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : [
                        route.meta
                          ? _c(
                              "el-submenu",
                              { key: index, attrs: { index: index + "" } },
                              [
                                _c(
                                  "template",
                                  { slot: "title" },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "menu-icon",
                                      staticStyle: { "margin-right": "16px" },
                                      attrs: {
                                        "icon-class":
                                          route.meta && route.meta.icon,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.generateTitle(route.meta.title)
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  route.children,
                                  function (item, itemIndex) {
                                    return [
                                      _vm.hasOneShowingChild(
                                        item.children,
                                        item
                                      ) &&
                                      (!_vm.onlyOneChild.children ||
                                        _vm.onlyOneChild.noShowingChildren) &&
                                      !item.alwaysShow
                                        ? [
                                            _c(
                                              "el-menu-item",
                                              {
                                                key: itemIndex,
                                                attrs: {
                                                  index:
                                                    route.path +
                                                    "/" +
                                                    item.path,
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "menu-icon",
                                                  staticStyle: {
                                                    "margin-right": "16px",
                                                  },
                                                  attrs: {
                                                    "icon-class":
                                                      item.meta &&
                                                      item.meta.icon,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: { slot: "title" },
                                                    slot: "title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.generateTitle(
                                                          item.meta.title
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : [
                                            _c(
                                              "el-submenu",
                                              {
                                                key: itemIndex + "",
                                                staticClass: "el-menu-second",
                                                attrs: {
                                                  index: itemIndex + "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass: "menu-icon",
                                                      staticStyle: {
                                                        "margin-right": "16px",
                                                      },
                                                      attrs: {
                                                        "icon-class":
                                                          item.meta &&
                                                          item.meta.icon,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.generateTitle(
                                                            item.meta.title
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  item.children,
                                                  function (
                                                    itemSecond,
                                                    secondIndex
                                                  ) {
                                                    return [
                                                      _c(
                                                        "el-menu-item",
                                                        {
                                                          key: secondIndex,
                                                          attrs: {
                                                            index:
                                                              route.path +
                                                              "/" +
                                                              item.path +
                                                              "/" +
                                                              itemSecond.path,
                                                          },
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            staticClass:
                                                              "menu-icon",
                                                            staticStyle: {
                                                              "margin-right":
                                                                "16px",
                                                            },
                                                            attrs: {
                                                              "icon-class":
                                                                itemSecond.meta &&
                                                                itemSecond.meta
                                                                  .icon,
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "title",
                                                              },
                                                              slot: "title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.generateTitle(
                                                                    itemSecond
                                                                      .meta
                                                                      .title
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }