import request from "@/utils/request";
var checkApi = {
  //  获取抽查司机列表
  geCheckDriver: function geCheckDriver(params) {
    return request({
      url: "/api/admin/spotCheckDriver/list",
      method: "get",
      params: params
    });
  },
  // 搜索司机（添加弹窗）
  searchDriver: function searchDriver(params) {
    return request({
      url: "/api/admin/spotCheckDriver/onlineDrivers",
      method: "get",
      params: params
    });
  },
  // 确认抽查司机
  sureCheckDriver: function sureCheckDriver(data) {
    return request({
      url: "/api/admin/spotCheckDriver/checks",
      method: "post",
      data: data
    });
  }
};
export default checkApi;