var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "defaultItem" }, [
    _c("div", { staticClass: "defaultItem-left" }, [
      _c(
        "div",
        { staticClass: "defaultItem-left-icon" },
        [_c("svg-icon", { attrs: { "icon-class": "callNotHandled" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "6px" } }, [
        _vm._v(_vm._s(_vm.isNull(_vm.item.agentname))),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(_vm._s(_vm.setDuration(_vm.item.begin, _vm.item.end))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "defaultItem-right" }, [
      _c("div", { staticClass: "defaultItem-right-title" }, [
        _c(
          "div",
          [
            _vm._v(
              "\n          " +
                _vm._s(_vm.isNull(_vm.item.name)) +
                "\n          "
            ),
            _c(
              "el-popover",
              {
                attrs: { placement: "top", width: "230" },
                model: {
                  value: _vm.visible,
                  callback: function ($$v) {
                    _vm.visible = $$v
                  },
                  expression: "visible",
                },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.fromData["name"],
                    callback: function ($$v) {
                      _vm.$set(_vm.fromData, "name", $$v)
                    },
                    expression: "fromData['name']",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { display: "flex", margin: "10px 0" } },
                  [
                    _c("el-checkbox", {
                      attrs: {
                        "true-label": 1,
                        "false-label": 0,
                        size: "mini",
                        label: "老年人",
                      },
                      model: {
                        value: _vm.fromData["isOldPerson"],
                        callback: function ($$v) {
                          _vm.$set(_vm.fromData, "isOldPerson", $$v)
                        },
                        expression: "fromData['isOldPerson']",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-checkbox", {
                      attrs: {
                        "true-label": 1,
                        "false-label": 0,
                        size: "mini",
                        label: "司机",
                      },
                      model: {
                        value: _vm.fromData["isDriver"],
                        callback: function ($$v) {
                          _vm.$set(_vm.fromData, "isDriver", $$v)
                        },
                        expression: "fromData['isDriver']",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      margin: "0",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            _vm.visible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.editorName()
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-edit-outline",
                  attrs: { slot: "reference" },
                  slot: "reference",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.item.isOldPerson,
                    expression: "item.isOldPerson",
                  },
                ],
                staticStyle: {
                  width: "48px",
                  height: "16px",
                  background: "#D65849",
                  "border-radius": "100px",
                  color: "#FFFFFF",
                  "text-align": "center",
                  "line-height": "16px",
                  padding: "0 5px",
                },
              },
              [_vm._v("老年人")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.item.isDriver,
                    expression: "item.isDriver",
                  },
                ],
                staticStyle: {
                  width: "48px",
                  height: "16px",
                  background: "#3a947b",
                  "border-radius": "100px",
                  color: "#FFFFFF",
                  "text-align": "center",
                  "line-height": "16px",
                  padding: "0 5px",
                },
              },
              [_vm._v("司机")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          " +
              _vm._s(
                _vm.isNull(_vm.item.ringingdate)
                  ? _vm.isDate(_vm.item.ringingdate)
                  : ""
              ) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "defaultItem-right-mobile" }, [
        _c("div", [
          _vm._v("\n          " + _vm._s(_vm.isNull(_vm.item.callno)) + " "),
          _c("span", [_vm._v(_vm._s(_vm.isNull(_vm.item.district)))]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.item.callstate === "Hangup" || _vm.item.callstate === "Unlink"
            ? _c("div", { staticStyle: { color: "#48AE71" } }, [
                _vm._v("已接听"),
              ])
            : _c("div", [
                _vm._v(_vm._s(_vm.setIvrkey(_vm.isNull(_vm.item.ivrkey)))),
              ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.item.tagging !== 0
        ? _c("div", { staticClass: "defaultItem-right-tag" }, [
            _c("div", [
              _vm._v(
                "\n          " + _vm._s(_vm.item.taggingText) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: { trigger: "click" },
                    on: { command: _vm.handleCommand },
                  },
                  [
                    _c("i", { staticClass: "el-icon-edit-outline" }),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(
                        _vm.setArray(_vm.dropdownlist),
                        function (item, index) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: index,
                              attrs: {
                                command: item.dicValue,
                                divided: _vm.isNull(item.isDivided),
                              },
                            },
                            [_vm._v(_vm._s(item.description))]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "defaultItem-right-tr" }),
      _vm._v(" "),
      _c("div", { staticClass: "defaultItem-right-keys" }, [
        _c("div", [
          _vm.item.tagging === 0
            ? _c(
                "div",
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleCommand },
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v("\n                标注\n              "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(
                          _vm.setArray(_vm.dropdownlist),
                          function (item, index) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: index,
                                attrs: {
                                  command: item.dicValue,
                                  divided: _vm.isNull(item.isDivided),
                                },
                              },
                              [_vm._v(_vm._s(item.description))]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticStyle: { color: "#BBBDC1" } }, [
                _vm._v("已标注"),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "keys-tr" }),
        _vm._v(" "),
        _c("div", { staticStyle: { color: "#BBBDC1" } }, [
          _vm._v("\n          回电\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "keys-tr" }),
        _vm._v(" "),
        _c("div", { on: { click: _vm.setRecord } }, [
          _vm._v("\n          记录\n        "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }