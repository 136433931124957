import request from "@/utils/request";

// 报警管理
var api = {
  // 获得报警信息
  getAlarmList: function getAlarmList(params) {
    return request({
      url: "api/admin/alarm/queryCallthepoliceInfo",
      method: "get",
      params: params
    });
  },
  //  处理报警信息
  dealAlarm: function dealAlarm(data) {
    return request({
      url: "api/admin/alarm/updateCallthepoliceInfo",
      method: "post",
      data: data
    });
  },
  // 查询司机信息
  getAlarmDriverDetail: function getAlarmDriverDetail(params) {
    return request({
      url: "api/admin/alarm/selectDriverInfoById",
      method: "get",
      params: params
    });
  }
};
export default api;