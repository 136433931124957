import _objectSpread from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView, CallDrawer } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from 'vuex';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView,
    CallDrawer: CallDrawer
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    },
    drawerState: function drawerState(state) {
      return state.drawer.drawerState;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  }),
  watch: {
    'sidebar.opened': function sidebarOpened(val) {
      var drawerState = this.drawerState;
      if (val && drawerState) {
        this.asideWidth = '470px';
      }
      if (val && !drawerState) {
        this.asideWidth = '200px';
      }
      if (!val && drawerState) {
        this.asideWidth = '334px';
      }
      if (!val && !drawerState) {
        this.asideWidth = '64px';
      }
    },
    drawerState: function drawerState(val) {
      var opened = this.sidebar.opened;
      if (val && opened) {
        this.asideWidth = '470px';
      }
      if (val && !opened) {
        this.asideWidth = '334px';
      }
      if (!val && opened) {
        this.asideWidth = '200px';
      }
      if (!val && !opened) {
        this.asideWidth = '64px';
      }
    }
  },
  data: function data() {
    return {
      refreshLostIntervalId: null,
      activeName: 'revenue',
      callStyle: {
        height: 0 + 'px'
      },
      callDrawerState: true,
      asideWidth: '200px'
      // styleWidth: this.sidebar.opened
    };
  },
  mounted: function mounted() {
    var opened = this.sidebar.opened;
    if (opened) {
      this.asideWidth = '200px';
    } else {
      this.asideWidth = '64px';
    }
    // const appWrapper = this.$refs.appWrapper
    // console.log(appWrapper)
    // const _this = this
    // this.refreshLostIntervalId = setInterval(() => {
    //   console.log('lost/loadStatistics')
    //   _this.$store.dispatch('lost/loadStatistics').then(res => {
    //     console.log('lost/loadStatistics', res)
    //   })
    // }, 30000)
  },
  destroyed: function destroyed() {
    if (this.refreshLostIntervalId) {
      clearInterval(this.refreshLostIntervalId);
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    /**
     * 关闭
     */
    shutDown: function shutDown() {
      this.$store.commit('drawer/DRAWER_STATE', false);
    }
  }
};