/* eslint-disable quotes */
/* eslint-disable semi */
// import axios from 'axios'
// import requestFilter from '@/utils/api-interceptor'
// import store from '@/store'
//
// const baseURL = process.env.VUE_APP_BASE_API
//
// // create an axios instance
// const request = axios.create({
//   baseURL: baseURL, // url = base url + request url
//   // withCredentials: true, // send cookies when cross-domain requests
//   timeout: 30 * 60 * 1000 // request timeout
// })
//
// // request interceptor
// request.interceptors.request.use(
//   config => {
//     // do something before request is sent
//     config.baseURL = store.state.supervise.url
//     const headers = store.state.supervise.head
//     if (headers) {
//       const rheaders = {}
//       rheaders[headers.csrfheaderName] = headers.csrfToken
//       config.headers = rheaders
//     }
//     return config
//   },
//   requestFilter.requestOnReject
// )
//
// request.interceptors.response.use(
//   requestFilter.responseInterceptor,
//   requestFilter.responseRejected
// )
import request from "@/utils/request";
var testApi = {
  postThreadCount: function postThreadCount(count) {
    return request({
      url: "api/admin/supervisor/threadcount",
      method: "post",
      params: {
        count: count
      }
    });
  },
  syncToSupervisor: function syncToSupervisor(data) {
    return request({
      url: "api/admin/supervisor/sync",
      method: "post",
      data: data
    });
  },
  getSyncTaskInfo: function getSyncTaskInfo() {
    return request({
      url: "api/admin/supervisor/syncTaskInfo",
      method: "get"
    });
  },
  getSyncData: function getSyncData(dataType) {
    return request({
      url: "api/admin/supervisor/data",
      method: "get",
      params: {
        dataType: dataType
      }
    });
  },
  getSyncDataStatus: function getSyncDataStatus(dataTypeList) {
    return request({
      url: "api/admin/supervisor/datastatus",
      method: "post",
      data: {
        dataTypeList: dataTypeList
      }
    });
  },
  // 服务器缓存数据
  postCacheData: function postCacheData(dataTypeList) {
    return request({
      url: "api/admin/supervisor/cachedata",
      method: "post",
      data: {
        dataTypeList: dataTypeList
      }
    });
  },
  getSyncOrders: function getSyncOrders(params) {
    return request({
      url: "api/admin/supervisor/orders",
      method: "get",
      params: params
    });
  },
  getOrderTrajectory: function getOrderTrajectory(orderId) {
    return request({
      url: "api/admin/supervisor/order/".concat(orderId, "/trajectory"),
      method: "get"
    });
  },
  getDriverPositionOrders: function getDriverPositionOrders() {
    return request({
      url: "api/admin/supervisor/driverPositionOrders",
      method: "get"
    });
  },
  getVehiclePositionOrders: function getVehiclePositionOrders() {
    return request({
      url: "api/admin/supervisor/vehiclePositionOrders",
      method: "get"
    });
  },
  // 查询车辆聚集配置
  getClusteringConfig: function getClusteringConfig(params) {
    return request({
      url: "/api/admin/clustering/props",
      method: "get",
      params: params
    });
  },
  /**
   * 更新车辆聚集数据
   * @param params 包含 meters, minPoints 两个属性
   * @returns {AxiosPromise}
   */
  postClusteringConfig: function postClusteringConfig(params) {
    return request({
      url: "/api/admin/clustering/props",
      method: "post",
      params: params
    });
  }
};
export default testApi;