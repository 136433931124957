import "core-js/modules/es6.regexp.constructor";
import _createForOfIteratorHelper from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import Mock from 'mockjs';
import { param2Obj } from "../src/utils";
import user from "./user";
import role from "./role";
import article from "./article";
import search from "./remote-search";
var mocks = [].concat(_toConsumableArray(user), _toConsumableArray(role), _toConsumableArray(article), _toConsumableArray(search));

// for front mock
// please use it cautiously, it will redefine XMLHttpRequest,
// which will cause many of your third-party libraries to be invalidated(like progress event).
export function mockXHR() {
  // mock patch
  // https://github.com/nuysoft/Mock/issues/300
  Mock.XHR.prototype.proxy_send = Mock.XHR.prototype.send;
  Mock.XHR.prototype.send = function () {
    if (this.custom.xhr) {
      this.custom.xhr.withCredentials = this.withCredentials || false;
      if (this.responseType) {
        this.custom.xhr.responseType = this.responseType;
      }
    }
    this.proxy_send.apply(this, arguments);
  };
  function XHR2ExpressReqWrap(respond) {
    return function (options) {
      var result = null;
      if (respond instanceof Function) {
        var body = options.body,
          type = options.type,
          url = options.url;
        // https://expressjs.com/en/4x/api.html#req
        result = respond({
          method: type,
          body: JSON.parse(body),
          query: param2Obj(url)
        });
      } else {
        result = respond;
      }
      return Mock.mock(result);
    };
  }
  var _iterator = _createForOfIteratorHelper(mocks),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var i = _step.value;
      Mock.mock(new RegExp(i.url), i.type || 'get', XHR2ExpressReqWrap(i.response));
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

// for mock server
var responseFake = function responseFake(url, type, respond) {
  return {
    url: new RegExp("/mock".concat(url)),
    type: type || 'get',
    response: function response(req, res) {
      res.json(Mock.mock(respond instanceof Function ? respond(req, res) : respond));
    }
  };
};
export default mocks.map(function (route) {
  return responseFake(route.url, route.type, route.response);
});