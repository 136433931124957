//
//
//
//
//
//
//
//

import _ from 'lodash';
export default {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      var elementResizeDetectorMaker = require('element-resize-detector');
      var vm = _this;
      var changesize = function changesize(element) {
        var width = element.offsetWidth;
        var height = element.offsetHeight;
        if (vm.$store.state.app.contentContainerSize.length === 0 && width && height) {
          vm.$store.commit('app/SET_CONTENT_CONTAINER_SIZE', [width, height]);
        }
        // console.log('changesize ', width, height)
      };
      elementResizeDetectorMaker().listenTo(document.getElementById('appMain'), _.debounce(changesize, 3000, {
        trailing: true
      }));
    });
  }
};