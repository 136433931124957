var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.divStyle },
    [
      _vm._l(_vm.options, function (item, index) {
        return [
          _vm.values.includes(item.value)
            ? [
                item.listClass == "default" || item.listClass == "" || _vm.plain
                  ? _c(
                      "span",
                      {
                        key: item.value,
                        class: item.cssClass,
                        attrs: { index: index },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  : _c(
                      "el-tag",
                      {
                        key: item.value,
                        class: item.cssClass,
                        attrs: {
                          "disable-transitions": true,
                          index: index,
                          type:
                            item.listClass == "primary" ? "" : item.listClass,
                        },
                      },
                      [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]
                    ),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }