//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorLog',
  data: function data() {
    return {
      dialogTableVisible: false
    };
  },
  computed: {
    errorLogs: function errorLogs() {
      return this.$store.getters.errorLogs;
    }
  }
};