import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getCheToken } from '@/utils/auth';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_CHE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10 * 1000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  // https://segmentfault.com/a/1190000018384777
  var url = config.url;
  // get参数编码
  if (config.method === 'get' && config.params) {
    url += '?';
    var keys = Object.keys(config.params);
    for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
      var key = _keys[_i];
      url += "".concat(key, "=").concat(encodeURIComponent(config.params[key]), "&");
    }
    url = url.substring(0, url.length - 1);
    config.params = {};
  }
  config.url = url;
  if (store.getters.cheToken) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['CHE_TOKEN'] = getCheToken();
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;

  // if the custom code is not 20000, it is judged as an error.
  if (res.status != '0') {
    Message({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;