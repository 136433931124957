import * as _ from 'lodash';
var state = {
  url: process.env.VUE_APP_BASE_API,
  head: ''
};
var mutations = {
  URL: function URL(state, data) {
    state.url = data;
  },
  SET_HEAD: function SET_HEAD(state, data) {
    state.head = data;
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};