import Cookies from 'js-cookie';
import { getLanguage } from '@/lang/index';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  language: getLanguage(),
  size: Cookies.get('size') || 'mini',
  version: process.env.VUE_APP_VERSION,
  internalName: process.env.VUE_APP_INTERVAL_NAME,
  intervalDescription: process.env.VUE_APP_INTERVAL_DESCRIPTION,
  hammerH5Url: process.env.VUE_APP_HAMMER_H5_URL,
  contentContainerSize: []
};
var mutations = {
  SET_CONTENT_CONTAINER_SIZE: function SET_CONTENT_CONTAINER_SIZE(state, data) {
    state.contentContainerSize = data || [];
  },
  HAMMER_H5_URL: function HAMMER_H5_URL(state, data) {
    state.hammerH5Url = data;
  },
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    Cookies.set('language', language);
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    Cookies.set('size', size);
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setLanguage: function setLanguage(_ref5, language) {
    var commit = _ref5.commit;
    commit('SET_LANGUAGE', language);
  },
  setSize: function setSize(_ref6, size) {
    var commit = _ref6.commit;
    commit('SET_SIZE', size);
  },
  setHammerH5Url: function setHammerH5Url(_ref7, url) {
    var commit = _ref7.commit;
    commit('HAMMER_H5_URL', url);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};