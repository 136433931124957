import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DictTag",
  props: {
    options: {
      type: Array,
      default: null
    },
    value: [Number, String, Array, Boolean],
    divStyle: Object,
    plain: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    values: function values() {
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value) ? this.value : typeof this.value === 'boolean' ? [this.value] : [this.value];
      } else {
        return [];
      }
    }
  }
};