/**
 * 从老版后台移过来的用户管理
 * */
import request from '@/utils/request';
export default {
  getAdminPermissions: function getAdminPermissions() {
    return request({
      url: '/api/admin/user/permissions',
      method: 'get'
    });
  },
  // 添加角色和权限
  createRoleGroups: function createRoleGroups(params) {
    return request({
      url: '/api/admin/user/roleGroups',
      method: 'post',
      data: params
    });
  },
  // 更新角色和权限
  updateRoleGroups: function updateRoleGroups(data) {
    return request({
      url: '/api/admin/user/roleGroups',
      method: 'put',
      data: data
    });
  },
  updateAdminRolePermissions: function updateAdminRolePermissions(roleId, userRoleObject) {
    return request({
      url: "/api/admin/user/roles/".concat(roleId, "/permissions"),
      method: 'post',
      data: userRoleObject
    });
  },
  getAdminRoles: function getAdminRoles() {
    return request({
      url: '/api/admin/user/roleGroups',
      method: 'get'
    });
  },
  delAdminRole: function delAdminRole(_ref) {
    var roleId = _ref.roleId;
    return request({
      url: "/api/admin/user/roleGroup/".concat(roleId),
      method: 'delete'
    });
  },
  getAdminUsers: function getAdminUsers(params) {
    return request({
      url: '/api/admin/user/adminUsers',
      method: 'get',
      params: params
    });
  },
  addAdminUser: function addAdminUser(adminUser) {
    return request({
      url: '/api/admin/user/adminUsers',
      method: 'POST',
      data: adminUser
    });
  },
  editAdminUsers: function editAdminUsers(adminUser) {
    return request({
      url: '/api/admin/user/adminUsers',
      method: 'put',
      data: adminUser
    });
  },
  delAdminUsers: function delAdminUsers(_ref2) {
    var userId = _ref2.userId;
    return request({
      url: "/api/admin/user/adminUser/".concat(userId),
      method: 'delete'
    });
  }
};