var state = {
  dicts: {
    businessType: [{
      label: '其他',
      value: 0
    }, {
      label: '新增',
      value: 1,
      listClass: 'success'
    }, {
      label: '更新',
      value: 2,
      listClass: 'warning'
    }, {
      label: '删除',
      value: 3,
      listClass: 'danger'
    }, {
      label: '授权',
      value: 4
    }, {
      label: '导出',
      value: 5
    }, {
      label: '导入',
      value: 6
    }, {
      label: '强退',
      value: 7
    }, {
      label: '生成代码',
      value: 8
    }, {
      label: '清空数据',
      value: 9
    }],
    userType: [{
      label: '其他',
      value: 0
    }, {
      label: '管理员',
      value: 1
    }, {
      label: '司机端',
      value: 2
    }, {
      label: '乘客端',
      value: 3
    }],
    opResult: [{
      label: '成功',
      value: 1,
      listClass: 'success'
    }, {
      label: '失败',
      value: 0,
      listClass: 'danger'
    }],
    boolFlag: [{
      label: '是',
      value: '1',
      listClass: 'success'
    }, {
      label: '否',
      value: '0',
      listClass: 'info'
    }],
    deleteFlag: [{
      label: '是',
      value: true,
      listClass: 'danger'
    }, {
      label: '否',
      value: false,
      listClass: 'info'
    }],
    booleanFlag: [{
      label: '是',
      value: true,
      listClass: 'success'
    }, {
      label: '否',
      value: false,
      listClass: ''
    }],
    typeOptions: [{
      "label": "其他",
      "value": 0
    }, {
      "label": "上画人员",
      "value": 1
    }, {
      "label": "其他公司机具人员",
      "value": 5
    }],
    platformTypeOptions: [{
      "label": "本平台",
      "value": 1
    }, {
      "label": "分控平台",
      "value": 2
    }],
    isActiveOptions: [{
      "label": "可用",
      "value": 1
    }, {
      "label": "禁用",
      "value": 0
    }],
    isBuildinOptions: [{
      "label": "是",
      "value": "1"
    }, {
      "label": "否",
      "value": "0"
    }]
  }
};
var mutations = {};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};