/**
 * 从长途车移过来的用户管理
 * */
import request from '@/utils/request';
export default {
  getAdminPermissions: function getAdminPermissions(params) {
    return request({
      url: '/api/admin/user/permissions',
      method: 'get',
      params: params
    });
  },
  updateAdminRolePermissions: function updateAdminRolePermissions(roleId, userRoleObject) {
    return request({
      url: "/api/admin/user/roles/".concat(roleId, "/permissions"),
      method: 'post',
      data: userRoleObject,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  getAdminRoles: function getAdminRoles(params) {
    return request({
      url: '/api/admin/user/roles',
      method: 'get',
      params: params
    });
  },
  getAdminUsers: function getAdminUsers(params) {
    return request({
      url: '/api/admin/user/list',
      method: 'get',
      params: params
    });
  },
  addAdminUser: function addAdminUser(adminUser) {
    return request({
      url: 'api/admin/user/users',
      method: 'post',
      data: adminUser
    });
  },
  adminUserTrees: function adminUserTrees() {
    return request({
      url: 'api/admin/user/trees',
      method: 'get'
    });
  },
  adminUserUpdateRole: function adminUserUpdateRole(params) {
    return request({
      url: '/api/admin/user/updateRole',
      method: 'post',
      data: params
    });
  },
  adminUserAddRole: function adminUserAddRole(params) {
    return request({
      url: '/api/admin/user/addRole',
      method: 'post',
      data: params
    });
  },
  adminUserDeleteRole: function adminUserDeleteRole(params) {
    return request({
      url: '/api/admin/user/deleteRole',
      method: 'post',
      data: params
    });
  },
  adminUserDeleteUser: function adminUserDeleteUser(params) {
    return request({
      url: '/api/admin/user/deleteUser',
      method: 'post',
      data: params
    });
  },
  adminUserResetPassword: function adminUserResetPassword(params) {
    return request({
      url: '/api/admin/user/resetPassword',
      method: 'post',
      data: params
    });
  },
  adminUpdateUserPassword: function adminUpdateUserPassword(params) {
    return request({
      url: '/api/admin/user/updateUserPassword',
      method: 'post',
      data: params
    });
  },
  getMenuList: function getMenuList(params) {
    // 菜单管理-查询菜单列表
    return request({
      url: '/api/admin/user/getMenuList',
      method: 'get',
      params: params
    });
  },
  updateMenu: function updateMenu(params) {
    // 菜单管理-修改菜单项
    return request({
      url: '/api/admin/user/updateMenu',
      method: 'post',
      data: params
    });
  },
  deleteMenu: function deleteMenu(params) {
    // 菜单管理-修改菜单项
    return request({
      url: '/api/admin/user/deleteMenu',
      method: 'get',
      params: params
    });
  },
  saveMenu: function saveMenu(params) {
    // 菜单管理-新增菜单项
    return request({
      url: '/api/admin/user/saveMenu',
      method: 'post',
      data: params
    });
  }
};