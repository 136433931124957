import request from '@/utils/request';
var api = {
  /**
   * 电话接入量
   * @param Object query
   * @returns Promirse
   */
  fetchList: function fetchList(query) {
    return request({
      url: '/api/admin/call-record-count/count-list',
      method: 'get',
      params: query
    });
  },
  /**
   * 城市列表获取
   */
  // cityList() {
  //   return request({
  //     url: 'http://127.0.0.1:4523/mock/953985/callStatistics/cityList',
  //     method: 'get'
  //   })
  // },
  /**
   * 获取客服列表
   * @returns Promirse
   */
  customList: function customList() {
    return request({
      url: '/api/admin/call-record-count/agent',
      method: 'get'
    });
  },
  /**
   * IVR列表接口
   * @param {请求体} query
   * @returns Promise
   */
  fetchIVRList: function fetchIVRList(query) {
    return request({
      url: '/api/admin/call-record-count/count-ivr-list',
      method: 'get',
      params: query
    });
  },
  /**
   * 呼入标签统计
   * @param {} query
   * @returns Promise
   */
  fetchTagList: function fetchTagList(query) {
    return request({
      url: '/api/admin/call-record-count/count-tagging-list',
      method: 'get',
      params: query
    });
  }
};
export default api;