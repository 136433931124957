import request from "@/utils/request";
var api = {
  queryQimoDictList: function queryQimoDictList(params) {
    // 七陌云标注字典值
    return request({
      url: '/api/admin/dict/queryQimoDictList',
      method: 'get',
      params: params
    });
  },
  queryDictList: function queryDictList(params) {
    return request({
      url: '/api/admin/dict/queryDictList',
      method: 'get',
      params: params
    });
  }
};
export default api;