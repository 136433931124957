import axios from 'axios';
import requestFilter from '@/api/taxigis-api-interceptor';
import store from '@/store';

// create an axios instance
var request = axios.create({
  baseURL: process.env.VUE_APP_GIS_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60 * 1000 // request timeout
});

// request interceptor
request.interceptors.request.use(function (config) {
  // do something before request is sent
  config.baseURL = store.state.taximon.url;
  return config;
}, requestFilter.requestOnReject);

// response interceptor
request.interceptors.response.use(requestFilter.responseInterceptor, requestFilter.responseRejected);
var taxiGisApi = {
  // 监控车辆
  getClusteringVehicles: function getClusteringVehicles(params) {
    return request({
      url: "api/gis/clustering/locations",
      method: 'get',
      params: params
    });
  },
  // 监控车辆
  startMonitorVehicle: function startMonitorVehicle(plateNumberArr, watchUser) {
    return request({
      url: "api/gis/monitor/vehicles",
      method: 'post',
      params: {
        watchUser: watchUser
      },
      data: plateNumberArr
    });
  },
  // 停止监控
  stopMonitorVehicle: function stopMonitorVehicle(plateNumber) {
    return request({
      url: "api/gis/monitor/vehicles",
      method: 'delete',
      params: {
        plateNumber: plateNumber
      }
    });
  },
  removeMonitorVehicle: function removeMonitorVehicle(plateNumber) {
    return request({
      url: "api/gis/monitor/vehicle/remove",
      method: 'post',
      params: {
        plateNumber: plateNumber
      }
    });
  },
  // 获取所有监控车辆
  getMonitorVehicles: function getMonitorVehicles() {
    return request({
      url: "api/gis/monitor/vehicles",
      method: 'get'
    });
  },
  getTrackVehicles: function getTrackVehicles() {
    return request({
      url: "api/gis/trackVehicles",
      method: 'get'
    });
  },
  //  http://localhost:9529/api/gis/circleSearchTrackVehicles?lat=22.813576&lng=108.341731&radius=300
  circleSearchTrackVehicles: function circleSearchTrackVehicles(_ref) {
    var lat = _ref.lat,
      lng = _ref.lng,
      radius = _ref.radius;
    return request({
      url: "api/gis/circleSearchTrackVehicles",
      method: 'get',
      params: {
        lat: lat,
        lng: lng,
        radius: radius
      }
    });
  },
  getQueryLocationByplateNumber: function getQueryLocationByplateNumber(params) {
    return request({
      url: "api/gis/queryLocationByplateNumber",
      method: 'get',
      params: params
    });
  }
};
export default taxiGisApi;