import * as _ from 'lodash';
var state = {
  url: process.env.VUE_APP_GIS_API
};
var mutations = {
  URL: function URL(state, data) {
    state.url = data;
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};