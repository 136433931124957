import request from "@/utils/request";
var api = {
  // 获取oss凭证
  getOss: function getOss() {
    return request({
      url: "/api/admin/order/aliyunOSSAccessToken",
      method: "get"
    });
  },
  getVehicleByPlateNum: function getVehicleByPlateNum(params) {
    // 根据车牌号获取司机信息
    return request({
      url: "/api/admin/order/getVehicleByPlateNum",
      method: "get",
      params: params
    });
  },
  getPrePlateNumByAreaCode: function getPrePlateNumByAreaCode(params) {
    // 根据行政区域编码获取车牌号前缀
    return request({
      url: "/api/admin/order/getPrePlateNumByAreaCode",
      method: "get",
      params: params
    });
  }
};
export default api;