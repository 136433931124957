var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "online" }, [
    _vm.da.mainStatus === "1"
      ? _c(
          "div",
          { staticClass: "online-have-Order" },
          [
            _c("svg-icon", { attrs: { "icon-class": "online-have-Order" } }),
            _vm._v(" "),
            _vm.da.source !== 4
              ? _c("span", [_vm._v("新订单")])
              : _c("span", [_vm._v("下单成功")]),
            _vm._v(" "),
            _vm.da.source === 4
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#435480FF" },
                  },
                  [_vm._v("后台")]
                )
              : _vm.da.source === 6
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#6974BFFF" },
                  },
                  [_vm._v("H5")]
                )
              : _vm.da.source === 0
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#FAAD14FF" },
                  },
                  [_vm._v("APP")]
                )
              : _vm.da.source === 2
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#46C25EFF" },
                  },
                  [_vm._v("微信")]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm.da.mainStatus === "2"
      ? _c(
          "div",
          { staticClass: "online-have-Order" },
          [
            _c("svg-icon", { attrs: { "icon-class": "online-have-Order" } }),
            _vm._v(" "),
            _c("span", [_vm._v("已接单")]),
            _vm._v(" "),
            _vm.da.source === 4
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#435480FF" },
                  },
                  [_vm._v("后台")]
                )
              : _vm.da.source === 6
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#6974BFFF" },
                  },
                  [_vm._v("H5")]
                )
              : _vm.da.source === 0
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#FAAD14FF" },
                  },
                  [_vm._v("APP")]
                )
              : _vm.da.source === 2
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#46C25EFF" },
                  },
                  [_vm._v("微信")]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm.da.mainStatus === "3"
      ? _c(
          "div",
          { staticClass: "online-have-been" },
          [
            _c("svg-icon", { attrs: { "icon-class": "online-have-been" } }),
            _vm._v(" "),
            _c("span", [_vm._v("已完成")]),
            _vm._v(" "),
            _vm.da.source === 4
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#435480FF" },
                  },
                  [_vm._v("后台")]
                )
              : _vm.da.source === 6
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#6974BFFF" },
                  },
                  [_vm._v("H5")]
                )
              : _vm.da.source === 0
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#FAAD14FF" },
                  },
                  [_vm._v("APP")]
                )
              : _vm.da.source === 2
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#46C25EFF" },
                  },
                  [_vm._v("微信")]
                )
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "online-have-cancelled" },
          [
            _c("svg-icon", {
              attrs: { "icon-class": "online-have-cancelled" },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("已取消")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.da.cancelmsg))]),
            _vm._v(" "),
            _vm.da.source === 4
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#435480FF" },
                  },
                  [_vm._v("后台")]
                )
              : _vm.da.source === 6
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#6974BFFF" },
                  },
                  [_vm._v("H5")]
                )
              : _vm.da.source === 0
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#FAAD14FF" },
                  },
                  [_vm._v("APP")]
                )
              : _vm.da.source === 2
              ? _c(
                  "span",
                  {
                    staticClass: "online-have-span",
                    staticStyle: { "background-color": "#46C25EFF" },
                  },
                  [_vm._v("微信")]
                )
              : _vm._e(),
          ],
          1
        ),
    _vm._v(" "),
    _c("div", { staticClass: "online-have-time" }, [
      _vm.da.mainStatus === "1"
        ? _c("span", [
            _vm._v(
              _vm._s(_vm.moment(_vm.da.createdOn).format("YYYY-MM-DD HH:mm:ss"))
            ),
          ])
        : _vm.da.mainStatus === "2"
        ? _c("span", [
            _vm._v(
              _vm._s(_vm.moment(_vm.da.matchTime).format("YYYY-MM-DD HH:mm:ss"))
            ),
          ])
        : _vm.da.mainStatus === "3"
        ? _c("span", { staticStyle: { color: "#787E8A" } }, [
            _vm._v(
              _vm._s(
                _vm.moment(_vm.da.arriveTime).format("YYYY-MM-DD HH:mm:ss")
              )
            ),
          ])
        : _c("span", { staticStyle: { color: "#D65849" } }, [
            _vm._v(
              _vm._s(
                _vm.moment(_vm.da.cancelTime).format("YYYY-MM-DD HH:mm:ss")
              )
            ),
          ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "online-content" }, [
      _c("div", [
        _c("div", { staticClass: "notify-content-car" }, [
          _vm._v(
            "\n        实时用车（" +
              _vm._s(_vm.da.origin && _vm.da.origin.city) +
              "）\n        "
          ),
          _vm.da.oldPeopleUse === 1
            ? _c("span", { staticStyle: { color: "#D65849" } }, [
                _vm._v("老年人用车"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.da.orderCallType === 1
            ? _c("span", { staticStyle: { color: "#6C63FFFF" } }, [
                _vm._v("爱心送考"),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "notify-content-mobile" }, [
          _vm._v(_vm._s(_vm.da.passenger && _vm.da.passenger.mobile)),
          _vm.da.passenger && _vm.da.passenger.nickName
            ? _c(
                "span",
                { staticStyle: { "margin-left": "5px", "font-size": "12px" } },
                [
                  _vm._v(
                    "(" +
                      _vm._s(
                        _vm.da.passenger ? _vm.da.passenger.nickName : ""
                      ) +
                      ")"
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                return _vm.view(_vm.da)
              },
            },
          },
          [_vm._v("查看>")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "n-address-bus" }, [
      _vm._v("•"),
      _c("span", [_vm._v(_vm._s(_vm.da.origin && _vm.da.origin.address))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "n-address-off" }, [
      _vm._v("•"),
      _c("span", [_vm._v(_vm._s(_vm.da.dest && _vm.da.dest.address))]),
    ]),
    _vm._v(" "),
    _vm.da.mainStatus === "2"
      ? _c("div", { staticClass: "n-letter" }, [
          _vm._v(
            _vm._s(
              _vm.da.driver && _vm.da.driver.plateNum
                ? _vm.da.driver.plateNum
                : "无"
            ) +
              "  " +
              _vm._s(
                _vm.da.driver && _vm.da.driver.driverType
                  ? _vm.da.driver.driverType
                  : "司机"
              ) +
              "  " +
              _vm._s(
                _vm.da.driver && _vm.da.driver.name
                  ? _vm.da.driver.name
                  : "司机"
              )
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }