import axios from 'axios';
import interceptor from '@/utils/api-interceptor';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000 // request timeout
});
interceptor.configAxios(service);
export default service;