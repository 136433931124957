import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    fit: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    config: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selection: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    expandRowKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    height: {
      type: Number
    },
    treeProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    defaultExpandAll: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    border: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    showSummary: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    highlightCurrentRow: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    sumText: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    tableRowClassName: {
      type: Function,
      default: function _default() {
        return '';
      }
    },
    summaryMethod: {
      type: Function
      // default() {
      //   return ''
      // }
    },
    rowKey: {
      type: String,
      default: function _default() {
        return 'id';
      }
    },
    maxHeight: {
      type: Number
    },
    stripe: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  methods: {
    /**
     * https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
     * @param o
     * @param s
     * @returns {*}
     */
    getByKey: function getByKey(o, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, ''); // strip a leading dot
      var a = s.split('.');
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    },
    select: function select(value) {
      this.$emit('select', value);
    },
    selectAll: function selectAll(value) {
      this.$emit('selectAll', value);
    },
    selectChange: function selectChange(value) {
      this.$emit('selectChange', value);
    },
    currentChange: function currentChange(value) {
      this.$emit('currentChange', value);
    },
    rowClick: function rowClick(row, column, event) {
      this.$emit('rowClick', row, column, event);
    },
    expandChange: function expandChange(row, expanded) {
      this.$emit('expandChange', row, expanded);
    }
  }
};